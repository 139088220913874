h1{
   color: $col-bg-solid;
   font-size: $heading-large;
   @extend .font--heading--default;
   font-weight: 900;
   line-height: $spacer-gutter;
}

h2{
    color: $col-bg-solid;
    font-size: $heading-medium;
    @extend .font--heading--default;
    @include margin($heading-medium-margin ,0, 0, 0);  
    line-height: 30px;
}

h3{
    color: $col-bg-solid;
    font-size: $heading-small;
    @extend .font--heading--default;
}

.heading-large{
    color: $col-heading-default;
    font-size: $heading-large;
    @extend .font--default--bold;
    font-weight: normal;
    line-height: $heading-large;   
}

.heading-small{
    color: $col-heading-default;
    font-size: $heading-small;
    @extend .font--default--bold;
    font-weight: bold;
    line-height: $heading-small;
    text-transform: uppercase;
    &__text{ 
        background: $col-heading-small-text-bg;
        padding: 0 $spacer-tiny 0 0;
    }
    &::after{
        content: '';
        width: $percentage-full;
        height: $spacer-thin;
        background: $col-heading-small-line;
        display: block;
        margin-top: $heading-small-line-margin;
    }
}