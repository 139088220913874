.create-request{
    .section-separator-bottom {
        border-bottom: $spacer-thin*2 solid $col-section-separator-border;
    }
    .display-flex {
        @include display-flex;
    }
    .move {
        background: none;
        font-size: 2em;
        border: none;
    }
    .group-active {
        cursor: pointer;
        border-bottom: $spacer-thin*2 solid $col-anchor-default;
    }
}

.customer-search-button {
        padding-top: $spacer-tiny * 4 +  $spacer-thin * 3;
}
.float-right{
    float: right;
}
.center-align{
    align-items: center;
}
.external-system-failure-box{
    background: $col-div-error-background;
    border: 1px solid $red;
    padding: $spacer-tiny;
}

.origin-element {
    width: $spacer-big * 5;
}

.destination-element {
    width: $spacer-big * 5;
}

.od-pair {
    #allowed-lbl {
        color:$dark-green;
    }
}

.success-text{
  color:$dark-green;
}

.od-pair {
    #disallowed-lbl {
        color:$red;
    }
}

/* Multi od remove absolute invalid locations icon pointer */
.remove-absolute-invalid-locations {
    cursor: pointer;
}
