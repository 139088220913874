.file-upload-box{
    height: $file-upload-box-height;
    width: $file-upload-box-width;
    border: $spacer-thin*2 dashed gray;
    display: table-cell;
    vertical-align: middle;

    &__content{
        margin: auto;
        width: $file-upload-box-content-height;
    }    
}

.upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        display: inline-block;
        input[type=file] { 
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
        }
        input[type=file]::-webkit-file-upload-button {
            cursor: pointer;
        }
}

.remove-file-icon{
    font-size: $spacer-medium;
    color: $col-download-icon;
    cursor: pointer;
}