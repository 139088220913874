.floating-panel{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    &__box{
        padding: $spacer-tiny;
        border: 1px solid $accent-secondary;
        margin: $spacer-tiny;
        border-radius: $spacer-tiny;
        background: $col-floating-box-bg;
        &--min-width{
            min-width: $spacer-cent;
        }
        &--disabled{
            background: $col-floating-box-disabled !important;
            border: 1px solid $col-floating-box-disabled;
            box-shadow: none !important;
            &:hover{
                background: $col-floating-box-disabled;
                color: $col-floating-box-disabled;
                
            }
            a{
                color: $col-floating-box-disabled-link !important;
            }
        }
    }

    &__checkbox{
        padding: 4px;
        border: 1px solid $accent-secondary;
        margin: $spacer-tiny;
        border-radius: $spacer-tiny;
        background: $col-floating-box-bg;
    }
}