.margin-small{
    &--all{
        @include margin( $spacer-small, $spacer-small,$spacer-small,$spacer-small);
    }
    &--top{
        @include margin( $spacer-small, 0, 0, 0);
    }
    &--bottom{
        @include margin( 0, 0, $spacer-small, 0);
    }
    &--left{
        @include margin( 0, 0, 0, $spacer-small);
    }
    &--right{
        @include margin( 0, $spacer-small, 0, 0);
    }
    &--left-right{
         @include margin( 0, $spacer-small, 0, $spacer-small);
    }
    &--top-bottom{
        @include margin( $spacer-small, 0, $spacer-small, 0);
    }
}

.margin-medium{
    &--all{
        @include margin( $spacer-medium, $spacer-medium,$spacer-medium,$spacer-medium);
    }
    &--top{
        @include margin( $spacer-medium, 0, 0, 0);
    }
    &--bottom{
        @include margin( 0, 0, $spacer-medium, 0);
    }
    &--left{
        @include margin( 0, 0, 0, $spacer-medium);
    }
    &--right{
        @include margin( 0, $spacer-medium, 0, 0);
    }
    &--left-right{
         @include margin( 0, $spacer-medium, 0, $spacer-medium);
    }
    &--top-bottom{
        @include margin( $spacer-medium, 0, $spacer-medium, 0);
    }
}

.margin-tiny{
    &--all{
        @include margin( $spacer-tiny, $spacer-tiny,$spacer-tiny,$spacer-tiny);
    }
    &--top{
        @include margin( $spacer-tiny, 0, 0, 0);
    }
    &--bottom{
        @include margin( 0, 0, $spacer-tiny, 0);
    }
    &--left{
        @include margin( 0, 0, 0, $spacer-tiny);
    }
    &--right{
        @include margin( 0, $spacer-tiny, 0, 0);
    }
    &--left-right{
         @include margin( 0, $spacer-tiny, 0, $spacer-tiny);
    }
    &--top-bottom{
        @include margin( $spacer-tiny, 0, $spacer-tiny, 0);
    }
}



.margin-large{
    &--all{
        @include margin( $spacer-large, $spacer-large,$spacer-large,$spacer-large);
    }
    &--top{
        @include margin( $spacer-large, 0, 0, 0);
    }
    &--bottom{
        @include margin( 0, 0, $spacer-large, 0);
    }
    &--left{
        @include margin( 0, 0, 0, $spacer-large);
    }
    &--right{
        @include margin( 0, $spacer-large, 0, 0);
    }
    &--left-right{
        @include margin( 0, $spacer-large, 0, $spacer-large);
    }
    &--top-bottom{
        @include margin( $spacer-large, 0, $spacer-large, 0);
    }
}

.padding-small{
    &--all{
        @include padding( $spacer-small, $spacer-small,$spacer-small,$spacer-small);
    }
    &--top{
        @include padding( $spacer-small, 0, 0, 0);
    }
    &--bottom{
        @include padding( 0, 0, $spacer-small, 0);
    }
    &--left{
        @include padding( 0, 0, 0, $spacer-small);
    }
    &--right{
        @include padding( 0, $spacer-small, 0, 0);
    }
    &--top-bottom{
        @include padding( $spacer-small, 0, $spacer-small, 0);
    }
    &--left-right{
        @include padding( 0, $spacer-small, 0, $spacer-small);
    }
    &--top-left{
        @include padding( $spacer-small, 0, 0, $spacer-small);
    }
}

.padding-medium{
    &--all{
        @include padding( $spacer-medium, $spacer-medium,$spacer-medium,$spacer-medium);
    }
    &--top{
        @include padding( $spacer-medium, 0, 0, 0);
    }
    &--bottom{
        @include padding( 0, 0, $spacer-medium, 0);
    }
    &--left{
        @include padding( 0, 0, 0, $spacer-medium);
    }
    &--right{
        @include padding( 0, $spacer-medium, 0, 0);
    }
    &--top-bottom{
        @include padding( $spacer-medium, 0, $spacer-medium, 0);
    }
    &--top-left{
        @include padding( $spacer-medium, 0, 0, $spacer-medium);
    }
}

.padding-tiny{
    &--all{
        @include padding( $spacer-tiny, $spacer-tiny,$spacer-tiny,$spacer-tiny);
    }
    &--top{
        @include padding( $spacer-tiny, 0, 0, 0);
    }
    &--bottom{
        @include padding( 0, 0, $spacer-tiny, 0);
    }
    &--left{
        @include padding( 0, 0, 0, $spacer-tiny);
    }
    &--right{
        @include padding( 0, $spacer-tiny, 0, 0);
    }
    &--top-bottom{
        @include padding( $spacer-tiny, 0, $spacer-tiny, 0);
    }
    &--left-right{
        @include padding( 0, $spacer-tiny, 0, $spacer-tiny);
    }
}

.padding-large{
    &--all{
        @include padding( $spacer-large, $spacer-large,$spacer-large,$spacer-large);
    }
    &--top{
        @include padding( $spacer-large, 0, 0, 0);
    }
    &--bottom{
        @include padding( 0, 0, $spacer-large, 0);
    }
    &--left{
        @include padding( 0, 0, 0, $spacer-large);
    }
    &--right{
        @include padding( 0, $spacer-large, 0, 0);
    }
    &--top-bottom{
        @include padding( $spacer-large, 0, $spacer-large, 0);
    }
    &--left-right{
        @include padding( 0, $spacer-large, 0, $spacer-large);
    }
}

.padding-tiny-only-top{
     padding-top: $spacer-tiny;
}

.content-margin{
    margin-top: $spacer-gutter;
}

.content-margin-small{
    margin-top: calc(#{$spacer-gutter} / 2);
}

.content-bold{
    line-height: $content-bold-line-height;
    letter-spacing: $content-bold-letter-spacing;
    font-weight: bold;
    @extend .font--default--bold;
    font-size: $font-size-default;
    color: $bg-dark;
}

.rotate180{
    transform: rotate(180deg);
}

.align-items-ends{
    justify-content: space-between;
}

.align-item-vertically-centered{
    align-items: center;
}


.div-bottom-margin{
    margin-bottom: $spacer-medium;
}

.link-align-right{
    text-align: right;
}

.text-link{
    text-decoration: underline;
    cursor: pointer;
}

.font-bold{
    font-weight: bold;
}

.open-close-container {
  border: 1px solid #dddddd;
  margin-top: 1em;
  padding: 20px 20px 0px 20px;
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}

.od_image_align {
    position: relative;
    top: $od-arrow-image-position-top;
}

.align{
    &--vertical{
        align-self: center;
    }
    &--bottom{
        align-self: flex-end;
    }
}

.od_arrow_img{
    width: $od-arrow-height;
    height: $od-arrow-width;
}

.form-content{
    background-color: $col-form-element-bg;
    padding: $spacer-tiny;
}

.no-wrap{
    white-space: nowrap;
}

.inline-display {

    display: inline-block;

    &--1 {
        width: $spacer-big;
    }

    &--2 {
        width: ($spacer-large * 2);
    }
}

.break-word{
    word-break: break-word !important;
}

.flex-middle{
    display: flex;
    align-items: flex-end;
    &_text{
        padding: ($spacer-tiny + $spacer-tiny * 0.5) 0 ($spacer-tiny + $spacer-tiny * 0.5) $spacer-tiny;
    }
}

.left{
  text-align: left;
}

.right{
  text-align: right !important;
}

.reset-padding{
    padding: 0px !important;
}

.flex-direction-column {
    flex-direction: column;
}

.no-wrap{
    white-space: nowrap;
}

.pointer{
    cursor: pointer;
}

.flex-align-left {
    justify-content: left;
}

.flex-align-end {
    justify-content: flex-end;
}
