.combo-box{
    position: relative;
    &__arrow{
        position: absolute;
        top: $combo-box-arrow-top;
        right: $combo-box-arrow-right;
        padding: $combo-box-arrow-padding-vert $combo-box-arrow-padding-horz $combo-bo-arrow-padding-right $combo-bo-arrow-padding-left;
        font-size: $combo-box-font-default;
        background: $combo-box-chevron-bg;
        cursor: pointer;
    }
    &__content{
        min-width: $percentage-full;
        position: absolute;
        z-index: 100;
        background: $white;
        &--full{
            width: $percentage-full;
        }
        > .suggestion-item{
            max-height: $combo-box-list-suggestion-height;
            > ul {
                > li:hover{
                    background: $col-list-item-background;
                }
            }
        }
        > .select-all{
            overflow: hidden;
        }
        > .selected-item {
            max-height: $combo-box-list-selected-list;
        }
    }
    &__item{
        border: $combo-box-border solid $col-combo-box-border;
        overflow-y: auto;
            overflow-x: hidden;
        > ul {
            list-style: none;
            > li{
                padding: $combo-box-list-padding-vert $combo-box-list-padding-horz;
            }
        }
        &--remove-border{
            border: 0;
        }
    }
    &__selected-header{
        font-size: $combo-box-font-small;
        padding: 0 $combo-box-header-padding;
    }
    
    
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background{
        background-color: $combo-box-check-box-color;
}
.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
    background-color: $combox-box-disabled-checkbox;
}