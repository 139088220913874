.table{
    width: $percentage-full;
    max-width: $percentage-full;
    border-spacing: 0;
    @extend .font--default--bold;
    > thead,
    > tbody,
    > tfoot {
        > tr {
            > td {
                padding: $table-padding-small;
                vertical-align: top;
                color: $col-table-body;
                border-bottom: 1px solid $col-border-table;
                > a {
                    color: $col-link-active;
                }
                .status-arrow{
                    font-size: $spacer-medium;
                }
            }
            > th{
                padding: $table-padding-tiny;
                color: $col-text-light;
                font-size: $font-size-header-default;
                text-align: left;
                vertical-align: bottom;
                > span {
                    display: table-cell;
                    .fa{
                        display: block;
                        padding: 0 0 0  calc(#{$spacer-tiny}/2);
                    }
                }
                .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background  {
                    background-color:#fafafa !important;
                }
            }
        }
    }
    // Bottom align for column headings
    > thead {
        background-color: $col-bg-solid;
        > tr {
            > th {
                padding-left: $table-padding-small;
            }
        }
    }
    > tbody > tr{
        background-color: $secondary-color;
    }
}
