%button-default{
    @extend .font--default--bold;
    font-weight: $font-weight-medium;
    letter-spacing: -0.1px;
    border: $border-thick solid transparent;
    cursor: pointer;
    outline: none;
}

.btn{
    @extend %button-default;
    padding: $button-top $spacer-small;
    &__primary{
        color: $col-btn-primary !important;
        background-color: $col-btn-primary-background !important;
        // Override for Angular Material
        &.mat-button{
            color: $col-btn-primary;
            border: $border-thick solid transparent;
            > .mat-button-focus-overlay{
                background: none;
            }
        }
        &:active:enabled{
            background-color: $col-btn-secondary-background;
            color: $col-btn-primary;
            border: $border-thick solid $col-btn-primary;
        }
        &:hover:enabled{
            background-color: $button-hover;
        }
        &--disabled{
            background-color: $col-content-disabled;
            color: $col-background-paper;
            cursor: not-allowed;
        }
    }
    &__secondary{
        color: $col-btn-secondary !important;
        background-color: $col-btn-secondary-background !important;
        // Override for Angular Material
        &.mat-button{
            color: $col-btn-secondary;
            border: $border-thick solid transparent;
            > .mat-button-focus-overlay{
                background: none;
            }
        }
        &:hover:enabled{
            color: $col-button-enable;
            border: $border-thick solid $col-btn-primary;
        }
        &--disabled{
            color: $col-content-disabled;
            background: none;
            cursor: not-allowed;
        }
        &--outlined{
            border: $border-thick solid $col-btn-secondary;
        }
    }
    &__light{
        color: $bg-dark !important;
        background: $bg-light !important;
        border: $border-thick solid $bg-light;
        // height: 50px !important;
        &.mat-button{
            border: $border-thick solid transparent;
            > .mat-button-focus-overlay{
                background: none;
            }
        }
        &:hover{
            border: $spacer-thin solid $border-dark;
            background: none !important;
        }
        &--active{
            border: $spacer-thin solid $border-dark !important;
            background: none !important;
        }
    }
    &--uppercase{
        text-transform: uppercase;
    }
    &--inline{
        display: inline-block;
    }
    &--responsive{
        width: $percentage-full;
    }
    &--large{
        height: $height-large !important;
    }
    &--medium{
        height: $button-medium !important;
    }
    &--round-btn{
        border-radius: $spacer-thin*4;
    }
    &--outline{
        border: $spacer-thin solid $border-dark;
    }
    &--download-bar{
        padding: $spacer-thin $download-req-padding-small;
    }
}

.plus-button{
    @include padding( 10px, 15px, 10px, 15px);
    background-color: $col-btn-primary-background;
    @extend .font--default--bold;
}

%button{
    width: auto;
    border: none;
    @extend .font--default--bold;
    @include border-radius(calc(#{$spacer-tiny}/2));
    cursor: pointer;
}

.load-more{
    @extend %button;
    background-color: $col-background-paper;
    color: $col-btn-secondary;
    width: $button-width-default;
    border: $spacer-thin solid $col-btn-secondary;
    border-radius: 0;
    outline: none;
}

.btn-small{
    min-width: $spacer-large !important;
}

.mdc-button {
    white-space: nowrap;
}

.mat-mdc-button.mat-mdc-button-base{
    margin-right: 4px;
}





