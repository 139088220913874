.tile{
   width: $tile-width;
   border: $spacer-thin solid $tbody-default-color;
   background-color: $white;
   text-align: center;
   margin: 0 auto;
   &__main{
    position: relative;
    text-align: center;
    height: $tile-head-height;
    border-bottom: $spacer-thin solid $tbody-default-color;
   }
   &__image{
       max-height: 100%;
       max-width: 100%;
   }
   &__header{
    height: $tile-header-height;
    width: $tile-header-width;
    position: absolute;
    left: 50%;
    margin-left: -$tile-header-height;
    top: 50%;
    margin-top: -($tile-header-height)*0.5;
    @extend .font--heading--default;   
   }
   &__text{
    margin-top: -($tile-header-height)*0.5;
    @extend .font--default;   
   }
   &__link{
    text-align: center;
    padding: $spacer-small 0;
    > a {
        color: $col-title-default;
        cursor: pointer;
    }
   }
}

