.ng-typeahead {   
    position: relative;
    padding: $spacer-tiny*0.5;
    & + .dropdown-menu {
        position: absolute;
        width: $spacer-big * 5;
        background: $col-typeahead-bg;
        border: 1px solid $col-typeahead-border;
        z-index: 10;
        & .dropdown-item {
            width: $percentage-full;
            background: $col-typeahead-bg;
            color: $col-typeahead-text;
            padding: $spacer-tiny;
            border: none;
            border-bottom: 1px solid $col-typeahead-border;
            list-style: none;
        }
    }
}
