
.service_contract_grid{
    &__content{
        max-width: $service-contract-grid-content-width;
    }
    &__label{
        max-width: $service-contract-grid-label-width;
    }
    &__row_height{
        min-height: $service-contract-grid-row-height;
    }
}

.od-history {
  &__section {
    outline: thin solid;
    padding: $od-history-padding;
    margin-bottom: $od-history-margin-bottom;
  }
  &__alternate {
    background-color: $grey4;
  }
}
