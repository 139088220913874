.download-request-details-bar {
     margin-left: 0px;
     height: $download-req-bar-height;
     width: 80%;
     background: linear-gradient(180deg,$download-request-bar-backround-color-1 0%, $download-request-bar-backround-color-2 100%);
     box-shadow: 0 $download-req-box-shadow ($download-req-box-shadow*2) 0 $download-request-bar-box-shadow-color;
     position: fixed;
     bottom: 0;
     transform: translate(-50%, 0);
     &--links{
         padding-left: 4%;
    }
     &--reqNumberBox{
         background-color: $col-background-secondary;
         text-align:center;
         margin-top: (-1 * $spacer-tiny);
    }
     &--text{
         color: $col-background-secondary;
         font-size: $label-font-size;
    }
     &--number{
         color: $col-background-paper;
         font-size: $label-font-size;
    }
     a{
         border-bottom:($spacer-thin * 2) solid $col-background-secondary;
         text-decoration: none;
    }
     &--disable{
         cursor: not-allowed;
         color: $col-disable-link;
         border-bottom: none !important;
         pointer-events:none;
    }
    &--right-border{
        border-right: $spacer-thin solid $grey1;
    }
    &--messages {
        white-space: nowrap;
        display: block;
        width: $video-width;
        text-align: center;
        line-height: $feedback-content-padding;
    }
}