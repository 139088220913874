.quarter-navigator-container{
    display: flex;
    align-items: center;
    & .quarter-arrow{
        font-size: $spacer-large;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        &--disabled{
            color: grey;
        }
    }
}
.quarter-dial-window{
    position: relative;
    margin: 0 auto;
    width: $quarter-dial-window-width;
    height: $quarter-dial-window-height;
    overflow: hidden;
}        
.quarter-dial{
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 800ms ease-in-out;
    & .item{
        width: $quarter-dial-item-width;
        padding: $quarter-dial-item-padding;
        text-align: center;
        color: inherit;
        &__dummy{
            color: $grey3;
        }        
        & .quarter{
            transition: all 800ms ease-in-out;
            &__active{
                color: $col-anchor-default;
                transform: scale(2);
            }
        }
        & .year{
            transition: color 800ms ease-in-out;
            &__active{
                color: $col-anchor-default;
            }
        }       
        
    }
}  