.sales_feedback_modal{
   
    &__header-font{
        font-weight: bold;        
    }
   
    &__close-button{
        cursor: pointer;
    }

    &--modal-box{
        .model{
            &__box{
                min-width: 1000px;
                margin: 0 auto;
            }
        }
    }
}
