$col-global-nav-hover:              $grey4; 
$col-shadow-button:                 $shadow-black;
$col-background-normal:             $bg-light;
$col-button-primary:                $accent-color;
$col-background-paper:              $white;
$col-text-light:                    $white;
$col-table-header-border:           $white;
$col-default-border:                $accent-color;
$col-background-default:            $accent-color;
$col-background-secondary:          $accent-secondary;
$col-title-default:                 $accent-secondary;
$col-button-enable:                 $button-hover;
$col-content-disabled:              $grey6;
$col-border-input:                  $grey3;
$col-border-table:                  $grey3;
$col-border-filter:                 $grey3;
$col-bg-solid:                      $bg-dark;
$col-label-default:                 $label-color-default;
$col-table-body:                    $tbody-default-color;
$col-link-active:                   $link-default;
// Typeahead colours
$col-typeahead-border:              $accent-color;
$col-typeahead-text:                $col-typeahead-border;
$col-typeahead-bg:                  $white;
$col-div-error-text:                $red1;
$col-section-separator-border:      $grey3;
$col-div-border-success:            $green1;

// Accordion Styling
$col-accordion-arrow:               $white;
$col-accordion-header-font:         $white;
$col-accordion-delete:              $red;

// Button Styles
$col-btn-primary:                   $secondary-color;
$col-btn-secondary:                 $accent-secondary;
$col-btn-primary-background:        $accent-secondary;
$col-btn-secondary-background:      $white;

// anchor Style
$col-anchor-default:                $accent-color;

// list-item Style
$col-list-item-background:          $accent-color;

// model style
$col-model-border:                  $grey5;
$col-model-content-background:      $white;
$col-model-color:                   $bg-dark;
$col-model-radio-checked:           $accent-secondary;
$col-model-radio-checked-ripple:    $mat-ripple-color;
$col-model-radio-border:            $accent-secondary;

// Jumbo Box
$col-background-jumbo:              $accent-color;

// Download icon 
$col-download-icon:                 $accent-color;

// styling for different status
$col-status:                        $bg-dark;
$col-status-yellow-1:               $yellow-1;
$col-status-yellow:                 $yellow;
$col-status-green:                  $green;
$col-status-red:                    $red;
$col-status-border:                 $bg-dark;
$col-status-grey:                   $grey6;


// Header
$col-user-label:                    $utilityBlack;

// combobox
$col-combo-box-border:              $grey3;
$combo-box-check-box-color:         $accent-secondary;
$combo-box-chevron-bg:              $white;
$combox-box-disabled-checkbox:      $grey8;


// snack bar
$col-snackback-background:          $shadow-black;

// User message div colours
$col-div-error-background:          $pink1;
$col-div-info-background:           $pink2;
$col-div-info-icon:                 $yellow-1;
$col-div-info-border:               $yellow-1;
$col-info-error-border:             $pink3;
$col-infor-error-background:        $error-color;

//releasenote
$releasenote-border:                $grey3;
$col-info-error-background:         $error-color;
$col-info-success-background:       $success-color;
$col-info-success-border:           $grey3;

//disable the link
$col-disable-link:                  $grey1;

//download req bar themes
$download-request-bar-backround-color-1:   $yellow-light;
$download-request-bar-backround-color-2:   $yellow-dark;
$download-request-bar-box-shadow-color:    $black-shadow;

// Customer details
$col-cust-div-background:           $accent-color;
$col-cust-div-label:                $accent-secondary;
$col-cust-div-label-text:           $accent-secondary;
$col-cust-div-res-header:           $white;

// navigation bar
// ==
$col-nav-menu-items:                $white;
$col-nav-menu-item-active:          $grey9;
$col-nav-menu-item-label-active:    $accent-secondary;
$col-nav-menu-item-hover:           $accent-color;

// Compliance Data 
$compliance-percentage:           $dark-green;
$compliance-ffe:                  $accent-color;
$compliance-today-bar:            $grey10;

// logout
$logout-box-border:               $grey3;
$logout-container-background:     $white;

// Product Type
$col-product-type-split-btn:        $black;
$col-product-type-split-btn-hover:  $white;
$col-product-calender-header:       $white;
$col-product-box-shadow:            $shadow-black;
$col-product-control-bg:            $white;  
$col-product-detail-bg:             $white;
$col-product-input-border:          $grey6;
$col-product-quarter-line:          $black;

// PLACE HOLDER Component
$col-place-holder-bg:               $grey4;
$col-place-holder-text:             rgba(0,0,0, 0.7);

$col-heading-default:               $black;
$col-heading-small-line:            $grey-line;
$col-heading-small-text-bg:         $white;

// Floating Panel box 
$col-floating-box-bg:               $white;
$col-floating-box-disabled:          $grey11;
$col-floating-box-disabled-link:     $grey2;

// form Element
$col-form-element-bg:               $grey11;
$col-form-element-border:           $grey6;

// date-validity
$col-validity-period:               $white;

// Curatilment action bar
$col-action-bar:                        $accent-color;
$col-action-bar-bg-gradient:            $accent-color-light;
$col-action-bar-float-box-bg:           $accent-secondary;
$col-ctl-datepicker-input-disabled:     $black;

// SIDEBAR
$col-drawer-bg:                 $accent-secondary;
$col-drawer-menu-color:         $accent-color;
$col-drawer-menu-active-bg:     $white;
$col-draweer-menu-link-color:   $white;

// service-contract-grid weekly breakdown
$service-contract-quarter-bg-color:                 $accent-color;
$service-contract-quarter-color:                    $white;
