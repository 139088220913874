.chips{
    background: $col-list-item-background;
    padding: $spacer-tiny*0.5 $spacer-tiny*0.5 $spacer-tiny*0.5 $spacer-small;
    border-radius: $spacer-small;
    @extend .font--default--bold;
    color: $secondary-color;
    display: inline-flex;
    margin: $spacer-tiny*0.5;
    &__close{
        margin-left: $spacer-tiny;
        background: $secondary-color;
        color: $accent-color;
        border-radius: $spacer-small;
        padding: 0 $spacer-tiny - 1;
        cursor: pointer;
    }

    &__without-close {
        .chips__close {
            display: none;
        }
        .chips__content {
            padding-right: ($spacer-small - 4);
        }
    }
    &__label{
        padding-left: ($spacer-small - 4);
        white-space: nowrap;
    }
}