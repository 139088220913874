.dialogue{
    position:fixed; 
    top:0;
    left:0;
    width: $percentage-full; 
    height: $percentage-full;
    background:rgba(0,0,0,0.4);
    &_content-box{
        position:absolute;
        top:50%;
        left:50%;
        margin-right:-50%;
        transform: translate(-50%, -50%);
        width:400px;
        background: #ffffff;
        &_header{
            background: #EDEDED; 
            padding:20px;
            font-weight:bold;
            @extend .font--heading--default;
        }
        &_content{
            padding: 30px 20px;
            @extend .font--default--bold;
            &--warning{
                color:red;
            }
        }
        &_prompt-action{
            background: #EDEDED;
            padding:20px;
        }
    }
    
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 16px;
}