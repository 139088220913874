.product{
    overflow: hidden;
    background-color: #F7F7F7;
    border: 1px solid #CCCCCC;
    &__view-port{
        
        &::-webkit-scrollbar {
            height: $product-scrollbar-height;
        }
        &::-webkit-scrollbar-track {
            background-color: $accent-color;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $accent-secondary;
            border-radius: $spacer-tiny;
        }
        overflow-x: auto;
        overflow-y: hidden;
        width: $product-view-port-width;
        margin: 0 auto;
        &__reel{
            min-width: 100%;
            margin-top: $spacer-small;
            margin-bottom: $spacer-small;
            &__fixed{
                height: $spacer-cent;
                &--offset{
                    padding: $spacer-tiny;
                }
                &--lbl{
                    white-space: nowrap;
                }
                .floating-panel__box{
                    padding: 0;
                    margin: $product-floating-panel-offset;
                    box-shadow: 0 0 $product-box-shadow-blur $product-box-shadow-spread $col-product-box-shadow;
                    &__vertical-anchor{
                        height: $percentage-full;
                        width: $percentage-full;
                        display: table;
                        a{
                            font-size: $product-control-font-size;
                            line-height: $product-anchor-font-size;
                            padding: $product-anchor-padding;
                            display: table-cell;
                            vertical-align: middle;
                            color: $col-product-type-split-btn;
                            &:hover{
                                color: $col-product-type-split-btn-hover;
                            }
                        }
                    }
                    &:hover{
                        background-color: $accent-secondary;
                    }
                }
                &--view{
                    position: absolute;
                    width: calc(100% - 30px);
                    .buffer-width{
                        width: ($spacer-jumbo *4) - $spacer-tiny;
                    }
                }
                &__lbl-ffe, &__ffe-input{
                    text-align: left;
                }
            }
            &__scrollable{
                &--offset{
                    padding: $product-scrollable-offset 0;
                }
                &__allocation{
                    input[type="text"], input[type="number"]{
                        width: 90%;
                    }
                }
            }
            &__calender{
                background-color: $accent-secondary;
                color: $col-product-calender-header;
            }
            &__quarter{
                position: relative;
                &::before{
                    content: '';
                    width: $percentage-full;
                    height: $spacer-thin;
                    background: $col-product-quarter-line;
                    display: block;
                    position: absolute;
                    top: $percentage-half;
                }
                &--back{
                    z-index: 100;
                    border-right: $spacer-thin solid $col-product-quarter-line;
                }
                &--offset{
                    padding: 0 $spacer-tiny;
                    background: $col-product-control-bg;
                    font-size: $product-quarter-font-size;
                }
            }
            &--line {
                box-shadow: 0 0 $product-box-shadow-blur $product-box-shadow-spread $col-product-box-shadow;
            }
            &--line2{
                box-shadow: 0 0 $product-box-shadow-blur $product-box-shadow-spread2 $col-product-box-shadow;
            }
            &--left{
                justify-content: flex-start;
            }
        }
        input[type="text"], input[type="number"]{
            padding: $product-input-padding;
            border: $spacer-thin solid $col-product-input-border;
            font-family: "Maersk Text";
            font-size: $font-size-default;
            text-align: center;
        }
        .col-1{
            text-align: center;
        }
    }
    &__guides{
        border-left: $spacer-thin solid $col-product-input-border;
        border-right: $spacer-thin solid $col-product-input-border;
    }
}

.product-details{
    &:nth-child(odd){
        background: $col-product-detail-bg;
    }
    box-shadow: $product-detail-box-shadow $col-product-box-shadow;
}
