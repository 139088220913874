.error-div{
    margin-top: $spacer-tiny;
    background: $col-div-error-background;
    border: 1px solid $red;
    padding: $spacer-tiny;
    display: flex;  
    align-items: center; 
    &--error-msg-link{        
        font-weight: bold;
        cursor: pointer;
    }
}

.info-div{
    margin-top: $spacer-tiny;
    background: $col-div-info-background;
    border: 1px solid $col-div-info-border;
    padding: $spacer-tiny;
    min-height: $spacer-gutter;
    display: flex;  
    align-items: center; 
}
.info-panel{
    padding-top: $spacer-thin*2;
    color: $col-div-info-icon;
    .fa{
        font-size: $spacer-medium;
    }
}
.error-panel{
    padding-top: $spacer-thin*2;
    color: $red;
    .fa{
        font-size: $spacer-medium;
    }
}

.info{
    margin-top: $spacer-tiny;
    padding: $info-padding;
    align-items: center;
    &__error{
        background: $col-info-error-background;
        border: $spacer-thin solid $col-info-error-border;
    }
    &--error {
        color: $red;
        font-size: $spacer-small;
    }
    &__warn{
        background: $col-div-info-background;
        border: $spacer-thin solid $col-div-info-border;
        display: flex;
        color: $col-div-info-icon;
    }
    &__success{
        background: $col-info-success-background;
        border: $spacer-thin solid $col-info-success-border;
        &--icon {
            color: $green1;
            font-size: $spacer-small;
        }
    }
    &__icon{
        font-size: $spacer-medium;
        margin: $spacer-tiny*0.5 $spacer-small 0 0;
    }
    &__close{
        padding: $error-close-padding-top $error-close-padding-left;
        border-radius: $error-close-border-radius;
        cursor: pointer;
        &--error{
            background: $accent-color;
            color: $white;
            display: inline-flex;
        }
    }
    &__curtailment{
        margin-bottom: $table-padding-tiny;
    }
}

.highlight-error{
    color: $red;
}
