.audit-trail{
    position: relative;
    z-index: 1;
    .alternate-row{
            background-color: $col-status-grey;
    }
    &__box{
        position: absolute;
        max-height: 600px;
        width: $percentage-full;
        top: -($spacer-medium + $spacer-tiny);
        background-color: $white;                        
        border: $spacer-thin solid;
        overflow: auto;
        & div{
            word-break: break-word;
        }      
    }
    &__header-font{
        font-weight: bold;
    } 
   
}
