.autocomplete {
    position: relative;
    .suggestions{
        position: absolute;
        max-height: $autocomplete-height;
        background: $white;
        width: $percentage-full;
        z-index: 100;
        overflow-y: auto;
        border: $autocomplete-border solid $col-combo-box-border;
        border-top: none;
        ul{
            list-style: none;
            padding: 0;
            margin: 0;
            li{
                padding: calc(#{$spacer-medium}/4) $spacer-small;
                opacity: 0.7;
            }
            li:hover{
                background: $col-list-item-background;
            }
            .active{
                background: $col-list-item-background;
            }
        }
        .error-message{
            padding: calc(#{$spacer-medium}/4) $spacer-small;
        }
    }
}

