@charset "UTF-8";
/*  Map deep get
	@author Hugo Giraudel
	@access public
	@param {Map} $map - Map
	@param {Arglist} $keys - Key chain
	@return {*} - Desired value*/
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
body, ul, li, h1, h2, h3 {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.table-cell {
  display: table-cell;
}

.hide {
  display: none;
}

::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A3A3A3;
  font-size: 16px;
  line-height: 24px;
  opacity: 1; /* Firefox */
}

.display--inline {
  display: inline-flex;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@font-face {
  font-family: "Rubik Medium";
  src: url("../../../assets/fonts/maeu/rubik-regular.eot");
  src: url("../../../assets/fonts/maeu/rubik-regular.eot?#iefix") format("embedded-opentype"), url("../../../assets/fonts/maeu/rubik-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Maersk Text";
  src: url("../../../assets/fonts/maeu/maersk-text-regular.otf");
  src: url("../../../assets/fonts/maeu/maersk-text-regular.otf?#iefix") format("embedded-opentype"), url("../../../assets/fonts/maeu/maersk-text-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*always put theme last */
/*  Map deep get
	@author Hugo Giraudel
	@access public
	@param {Map} $map - Map
	@param {Arglist} $keys - Key chain
	@return {*} - Desired value*/
/* Custom, iPhone Retina */
/* Extra Small Devices, Phones */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
/* Small Devices, Tablets */
/* Medium Devices, Desktops */
/* Large Devices, Wide Screens */
body, ul, li, h1, h2, h3 {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.table-cell {
  display: table-cell;
}

.hide {
  display: none;
}

::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #A3A3A3;
  font-size: 16px;
  line-height: 24px;
  opacity: 1; /* Firefox */
}

.display--inline {
  display: inline-flex;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.font--default, .tile__text {
  font-family: "Maersk Text";
  font-size: 14px;
  line-height: 24px;
}

.font--default--bold, .pending-tray .pagination, .chips, .customerDetailsSearch__searchResults .label, .quantity-selector__increamenter, .model__box__header, a, .mat-icon, .drawer-sidenav__menu__item__text, .footer__copyright, nav .role-lable, nav .user-label, .dialogue_content-box_content, .table, .label-text_only_wrap, .label-text, .label-od-text, label, .load-more, .plus-button, .btn, .page-title, .heading-small, .heading-large, .content-bold, body {
  font-family: "Maersk Text";
  font-size: 14px;
  line-height: 24px;
}

.font--heading--default, .tile__header, .dialogue_content-box_header, h3, h2, h1 {
  font-family: "Zeta Serif";
  font-size: 14px;
  line-height: 24px;
}

.container {
  width: 1140px;
  margin: 0 auto;
}

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center; /* align horizontal */
  justify-content: center;
}
.row--disabled {
  opacity: 0.7;
}

.col-1 {
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-grow: 1;
  -moz-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-basis: 100%;
}

.col-2 {
  -webkit-flex-grow: 2;
  -moz-flex-grow: 2;
  -ms-flex-grow: 2;
  flex-grow: 2;
  -webkit-flex-grow: 2;
  -moz-flex-grow: 2;
  -ms-flex-grow: 2;
  flex-basis: 200%;
}

.col-3 {
  -webkit-flex-grow: 3;
  -moz-flex-grow: 3;
  -ms-flex-grow: 3;
  flex-grow: 3;
  -webkit-flex-grow: 3;
  -moz-flex-grow: 3;
  -ms-flex-grow: 3;
  flex-basis: 300%;
}

.col-4 {
  -webkit-flex-grow: 4;
  -moz-flex-grow: 4;
  -ms-flex-grow: 4;
  flex-grow: 4;
  -webkit-flex-grow: 4;
  -moz-flex-grow: 4;
  -ms-flex-grow: 4;
  flex-basis: 400%;
}

.col-5 {
  -webkit-flex-grow: 5;
  -moz-flex-grow: 5;
  -ms-flex-grow: 5;
  flex-grow: 5;
  -webkit-flex-grow: 5;
  -moz-flex-grow: 5;
  -ms-flex-grow: 5;
  flex-basis: 500%;
}

.col-6 {
  -webkit-flex-grow: 6;
  -moz-flex-grow: 6;
  -ms-flex-grow: 6;
  flex-grow: 6;
  -webkit-flex-grow: 6;
  -moz-flex-grow: 6;
  -ms-flex-grow: 6;
  flex-basis: 600%;
}

.col-7 {
  -webkit-flex-grow: 7;
  -moz-flex-grow: 7;
  -ms-flex-grow: 7;
  flex-grow: 7;
  -webkit-flex-grow: 7;
  -moz-flex-grow: 7;
  -ms-flex-grow: 7;
  flex-basis: 700%;
}

.col-8 {
  -webkit-flex-grow: 8;
  -moz-flex-grow: 8;
  -ms-flex-grow: 8;
  flex-grow: 8;
  -webkit-flex-grow: 8;
  -moz-flex-grow: 8;
  -ms-flex-grow: 8;
  flex-basis: 800%;
}

.col-9 {
  -webkit-flex-grow: 9;
  -moz-flex-grow: 9;
  -ms-flex-grow: 9;
  flex-grow: 9;
  -webkit-flex-grow: 9;
  -moz-flex-grow: 9;
  -ms-flex-grow: 9;
  flex-basis: 900%;
}

.col-10 {
  -webkit-flex-grow: 10;
  -moz-flex-grow: 10;
  -ms-flex-grow: 10;
  flex-grow: 10;
  -webkit-flex-grow: 10;
  -moz-flex-grow: 10;
  -ms-flex-grow: 10;
  flex-basis: 1000%;
}

.col-11 {
  -webkit-flex-grow: 11;
  -moz-flex-grow: 11;
  -ms-flex-grow: 11;
  flex-grow: 11;
  -webkit-flex-grow: 11;
  -moz-flex-grow: 11;
  -ms-flex-grow: 11;
  flex-basis: 1100%;
}

.col-12 {
  -webkit-flex-grow: 12;
  -moz-flex-grow: 12;
  -ms-flex-grow: 12;
  flex-grow: 12;
  -webkit-flex-grow: 12;
  -moz-flex-grow: 12;
  -ms-flex-grow: 12;
  flex-basis: 1200%;
}

.col-13 {
  -webkit-flex-grow: 13;
  -moz-flex-grow: 13;
  -ms-flex-grow: 13;
  flex-grow: 13;
  -webkit-flex-grow: 13;
  -moz-flex-grow: 13;
  -ms-flex-grow: 13;
  flex-basis: 1300%;
}

.margin-small--all {
  margin: 16px 16px 16px 16px;
}
.margin-small--top {
  margin: 16px 0 0 0;
}
.margin-small--bottom {
  margin: 0 0 16px 0;
}
.margin-small--left {
  margin: 0 0 0 16px;
}
.margin-small--right {
  margin: 0 16px 0 0;
}
.margin-small--left-right {
  margin: 0 16px 0 16px;
}
.margin-small--top-bottom {
  margin: 16px 0 16px 0;
}

.margin-medium--all {
  margin: 24px 24px 24px 24px;
}
.margin-medium--top {
  margin: 24px 0 0 0;
}
.margin-medium--bottom {
  margin: 0 0 24px 0;
}
.margin-medium--left {
  margin: 0 0 0 24px;
}
.margin-medium--right {
  margin: 0 24px 0 0;
}
.margin-medium--left-right {
  margin: 0 24px 0 24px;
}
.margin-medium--top-bottom {
  margin: 24px 0 24px 0;
}

.margin-tiny--all {
  margin: 8px 8px 8px 8px;
}
.margin-tiny--top {
  margin: 8px 0 0 0;
}
.margin-tiny--bottom {
  margin: 0 0 8px 0;
}
.margin-tiny--left {
  margin: 0 0 0 8px;
}
.margin-tiny--right {
  margin: 0 8px 0 0;
}
.margin-tiny--left-right {
  margin: 0 8px 0 8px;
}
.margin-tiny--top-bottom {
  margin: 8px 0 8px 0;
}

.margin-large--all {
  margin: 48px 48px 48px 48px;
}
.margin-large--top {
  margin: 48px 0 0 0;
}
.margin-large--bottom {
  margin: 0 0 48px 0;
}
.margin-large--left {
  margin: 0 0 0 48px;
}
.margin-large--right {
  margin: 0 48px 0 0;
}
.margin-large--left-right {
  margin: 0 48px 0 48px;
}
.margin-large--top-bottom {
  margin: 48px 0 48px 0;
}

.padding-small--all {
  padding: 16px 16px 16px 16px;
}
.padding-small--top {
  padding: 16px 0 0 0;
}
.padding-small--bottom {
  padding: 0 0 16px 0;
}
.padding-small--left {
  padding: 0 0 0 16px;
}
.padding-small--right {
  padding: 0 16px 0 0;
}
.padding-small--top-bottom {
  padding: 16px 0 16px 0;
}
.padding-small--left-right {
  padding: 0 16px 0 16px;
}
.padding-small--top-left {
  padding: 16px 0 0 16px;
}

.padding-medium--all {
  padding: 24px 24px 24px 24px;
}
.padding-medium--top {
  padding: 24px 0 0 0;
}
.padding-medium--bottom {
  padding: 0 0 24px 0;
}
.padding-medium--left {
  padding: 0 0 0 24px;
}
.padding-medium--right {
  padding: 0 24px 0 0;
}
.padding-medium--top-bottom {
  padding: 24px 0 24px 0;
}
.padding-medium--top-left {
  padding: 24px 0 0 24px;
}

.padding-tiny--all {
  padding: 8px 8px 8px 8px;
}
.padding-tiny--top {
  padding: 8px 0 0 0;
}
.padding-tiny--bottom {
  padding: 0 0 8px 0;
}
.padding-tiny--left {
  padding: 0 0 0 8px;
}
.padding-tiny--right {
  padding: 0 8px 0 0;
}
.padding-tiny--top-bottom {
  padding: 8px 0 8px 0;
}
.padding-tiny--left-right {
  padding: 0 8px 0 8px;
}

.padding-large--all {
  padding: 48px 48px 48px 48px;
}
.padding-large--top {
  padding: 48px 0 0 0;
}
.padding-large--bottom {
  padding: 0 0 48px 0;
}
.padding-large--left {
  padding: 0 0 0 48px;
}
.padding-large--right {
  padding: 0 48px 0 0;
}
.padding-large--top-bottom {
  padding: 48px 0 48px 0;
}
.padding-large--left-right {
  padding: 0 48px 0 48px;
}

.padding-tiny-only-top {
  padding-top: 8px;
}

.content-margin {
  margin-top: 40px;
}

.content-margin-small {
  margin-top: calc(40px / 2);
}

.content-bold {
  line-height: 17px;
  letter-spacing: -0.1px;
  font-weight: bold;
  font-size: 14px;
  color: #313131;
}

.rotate180 {
  transform: rotate(180deg);
}

.align-items-ends {
  justify-content: space-between;
}

.align-item-vertically-centered {
  align-items: center;
}

.div-bottom-margin {
  margin-bottom: 24px;
}

.link-align-right {
  text-align: right;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.font-bold {
  font-weight: bold;
}

.open-close-container {
  border: 1px solid #dddddd;
  margin-top: 1em;
  padding: 20px 20px 0px 20px;
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}

.od_image_align {
  position: relative;
  top: 30px;
}

.align--vertical {
  align-self: center;
}
.align--bottom {
  align-self: flex-end;
}

.od_arrow_img {
  width: 36px;
  height: 36px;
}

.form-content {
  background-color: #F7F7F7;
  padding: 8px;
}

.no-wrap {
  white-space: nowrap;
}

.inline-display {
  display: inline-block;
}
.inline-display--1 {
  width: 56px;
}
.inline-display--2 {
  width: 96px;
}

.break-word {
  word-break: break-word !important;
}

.flex-middle {
  display: flex;
  align-items: flex-end;
}
.flex-middle_text {
  padding: 12px 0 12px 8px;
}

.left {
  text-align: left;
}

.right {
  text-align: right !important;
}

.reset-padding {
  padding: 0px !important;
}

.flex-direction-column {
  flex-direction: column;
}

.no-wrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.flex-align-left {
  justify-content: left;
}

.flex-align-end {
  justify-content: flex-end;
}

h1 {
  color: #313131;
  font-size: 40px;
  font-weight: 900;
  line-height: 40px;
}

h2 {
  color: #313131;
  font-size: 30px;
  margin: 28px 0 0 0;
  line-height: 30px;
}

h3 {
  color: #313131;
  font-size: 20px;
}

.heading-large {
  color: #282828;
  font-size: 40px;
  font-weight: normal;
  line-height: 40px;
}

.heading-small {
  color: #282828;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  text-transform: uppercase;
}
.heading-small__text {
  background: #FFFFFF;
  padding: 0 8px 0 0;
}
.heading-small::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #DDDDDD;
  display: block;
  margin-top: -10px;
}

.slider__week-allocation {
  width: 840px;
}
.slider__view-port {
  overflow: hidden;
}
.slider__view-port-reel {
  display: flex;
  transition: transform 1s ease-in-out;
}
.slider__view-port-td {
  padding: 10px 0 !important;
  height: 48px;
  width: 7%;
  vertical-align: middle !important;
}
.slider__view-port-text-td {
  padding: 2px !important;
  font-weight: bold;
  height: 48px;
  vertical-align: middle !important;
}
.slider__view-port--tr {
  background-color: #FFFFFF !important;
  height: 85px;
}
.slider__content {
  flex-basis: 100%;
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.slider__content .week-volume-edit {
  padding: calc(8px / 2);
  text-align: center;
}
.slider__controls {
  cursor: pointer;
  align-items: center;
}
.slider__controls-arrow {
  font-size: 48px;
}
.slider__controls-quarter {
  width: 33%;
  padding: 16px;
  display: table-cell;
}
.slider__controls-quarter-dummy {
  color: #A3A3A3;
}
.slider__quarter-number {
  font-size: 14px;
  transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out, color 1s ease-in-out;
}
.slider__quarter-year {
  font-size: 14px;
  transition: color 1s ease-in-out;
}
.slider__error {
  border: 1px solid #D0021B;
}

.arrow-disabled {
  color: #A3A3A3;
}

.active-quarter {
  color: #69B8D6;
}
.active-quarter .slider__quarter-number {
  transform: scale(2);
}

.legends {
  color: #000000;
  font-style: italic;
  font-size: 14px;
}

.split-disabled {
  pointer-events: none;
  color: #CCCCCC;
  background: none;
  box-shadow: none;
}

.error-panel {
  padding-top: 2px;
  display: flex;
  color: #D0021B;
}
.error-panel .fa {
  font-size: 24px;
}

.previous-volume-cbk {
  padding-top: 48px;
}

.table-layout {
  table-layout: fixed;
}

.weekVolumeSummaryRow {
  font-size: calc(24px / 2);
}

.adjustWeeksPaading {
  padding-top: 22px !important;
}

.adjustWeeksSummaryPaading {
  padding-bottom: 10px !important;
}

.cav-validity {
  color: #D0021B;
}

.weekly-input {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px dotted #FFFFFF;
  color: #FFFFFF;
  outline: none;
  width: 84px;
}

.page-title {
  cursor: pointer;
  color: #003E5E;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  display: inline-block;
}
.page-title > i {
  font-size: 20px;
}
.page-title > span {
  padding: 0 8px;
}

.padding-right, .padding-left {
  padding: 0 20px 0 0;
}

.filter-section {
  padding: 20px;
  border: 1px solid #A3A3A3;
}

input[type=text], textarea {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 2px solid #CCCCCC;
}

textarea {
  resize: none;
}

select {
  width: 100%;
  font-size: 16px;
  border: 2px solid #CCCCCC;
  padding: 0 0 0 16px;
}

.input-large {
  height: 50px;
  border: 1px solid #979797 !important;
  box-shadow: 0 0 calc(8px/2) calc(-8px/2) #000000;
}

.input-error {
  border: 2px solid #D0021B !important;
}

.input-default {
  height: 46px;
}

.custom-search {
  position: relative;
}
.custom-search .input-search-icon {
  position: absolute;
  top: 16px;
  right: 30px;
  font-size: 1.2em;
  color: #A3A3A3;
}

.textarea-default {
  height: 72px;
  border: 2px solid #A3A3A3;
}

.input-small {
  height: 24px;
  border: 2px solid #CCCCCC;
}

.input-highlight {
  border: 2px solid #69B8D6 !important;
}

.input-highlight-cav {
  background: #ffbf00;
  border: 2px solid #0000FF;
}

.input-top-padding {
  position: relative;
  top: 22px;
  margin: 0px 0px 0px 10px;
}

.btn {
  font-weight: 500;
  letter-spacing: -0.1px;
  border: 2px solid transparent;
  cursor: pointer;
  outline: none;
}

.btn {
  padding: 6px 16px;
}
.btn__primary {
  color: #FAFAFA !important;
  background-color: #003E5E !important;
}
.btn__primary.mat-button {
  color: #FAFAFA;
  border: 2px solid transparent;
}
.btn__primary.mat-button > .mat-button-focus-overlay {
  background: none;
}
.btn__primary:active:enabled {
  background-color: #FFFFFF;
  color: #FAFAFA;
  border: 2px solid #FAFAFA;
}
.btn__primary:hover:enabled {
  background-color: #86C1EF;
}
.btn__primary--disabled {
  background-color: #CCCCCC;
  color: #FFFFFF;
  cursor: not-allowed;
}
.btn__secondary {
  color: #003E5E !important;
  background-color: #FFFFFF !important;
}
.btn__secondary.mat-button {
  color: #003E5E;
  border: 2px solid transparent;
}
.btn__secondary.mat-button > .mat-button-focus-overlay {
  background: none;
}
.btn__secondary:hover:enabled {
  color: #86C1EF;
  border: 2px solid #FAFAFA;
}
.btn__secondary--disabled {
  color: #CCCCCC;
  background: none;
  cursor: not-allowed;
}
.btn__secondary--outlined {
  border: 2px solid #003E5E;
}
.btn__light {
  color: #313131 !important;
  background: #EEEEEE !important;
  border: 2px solid #EEEEEE;
}
.btn__light.mat-button {
  border: 2px solid transparent;
}
.btn__light.mat-button > .mat-button-focus-overlay {
  background: none;
}
.btn__light:hover {
  border: 1px solid #979797;
  background: none !important;
}
.btn__light--active {
  border: 1px solid #979797 !important;
  background: none !important;
}
.btn--uppercase {
  text-transform: uppercase;
}
.btn--inline {
  display: inline-block;
}
.btn--responsive {
  width: 100%;
}
.btn--large {
  height: 50px !important;
}
.btn--medium {
  height: 46px !important;
}
.btn--round-btn {
  border-radius: 4px;
}
.btn--outline {
  border: 1px solid #979797;
}
.btn--download-bar {
  padding: 1px 12px;
}

.plus-button {
  padding: 10px 15px 10px 15px;
  background-color: #003E5E;
}

.load-more {
  width: auto;
  border: none;
  -webkit-border-radius: calc(8px/2);
  -moz-border-radius: calc(8px/2);
  -ms-border-radius: calc(8px/2);
  border-radius: calc(8px/2);
  cursor: pointer;
}

.load-more {
  background-color: #FFFFFF;
  color: #003E5E;
  width: 114px;
  border: 1px solid #003E5E;
  border-radius: 0;
  outline: none;
}

.btn-small {
  min-width: 48px !important;
}

.mdc-button {
  white-space: nowrap;
}

.mat-mdc-button.mat-mdc-button-base {
  margin-right: 4px;
}

label {
  opacity: 0.7;
  color: #222222;
  font-size: 16px;
  line-height: 24px;
}

.label-text, .label-od-text {
  word-break: break-all;
  word-wrap: break-word;
}

.label-text_only_wrap {
  word-wrap: break-word;
}

.label-od-text {
  font-weight: bold;
}

.od-text {
  font-size: 16px;
}

.week-number-info {
  margin-top: 21px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.table {
  width: 100%;
  max-width: 100%;
  border-spacing: 0;
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 17px;
  vertical-align: top;
  color: #737373;
  border-bottom: 1px solid #A3A3A3;
}
.table > thead > tr > td > a,
.table > tbody > tr > td > a,
.table > tfoot > tr > td > a {
  color: #2196F3;
}
.table > thead > tr > td .status-arrow,
.table > tbody > tr > td .status-arrow,
.table > tfoot > tr > td .status-arrow {
  font-size: 24px;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  padding: 5px;
  color: #FFFFFF;
  font-size: 14px;
  text-align: left;
  vertical-align: bottom;
}
.table > thead > tr > th > span,
.table > tbody > tr > th > span,
.table > tfoot > tr > th > span {
  display: table-cell;
}
.table > thead > tr > th > span .fa,
.table > tbody > tr > th > span .fa,
.table > tfoot > tr > th > span .fa {
  display: block;
  padding: 0 0 0 calc(8px/2);
}
.table > thead > tr > th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.table > tbody > tr > th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background,
.table > tfoot > tr > th .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true]) ~ .mdc-checkbox__background {
  background-color: #fafafa !important;
}
.table > thead {
  background-color: #313131;
}
.table > thead > tr > th {
  padding-left: 17px;
}
.table > tbody > tr {
  background-color: #FAFAFA;
}

.autocomplete {
  position: relative;
}
.autocomplete .suggestions {
  position: absolute;
  max-height: 180px;
  background: #FFFFFF;
  width: 100%;
  z-index: 100;
  overflow-y: auto;
  border: 1px solid #A3A3A3;
  border-top: none;
}
.autocomplete .suggestions ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.autocomplete .suggestions ul li {
  padding: calc(24px/4) 16px;
  opacity: 0.7;
}
.autocomplete .suggestions ul li:hover {
  background: #69B8D6;
}
.autocomplete .suggestions ul .active {
  background: #69B8D6;
}
.autocomplete .suggestions .error-message {
  padding: calc(24px/4) 16px;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-icon-color: #003E5E;
  --mdc-radio-unselected-pressed-icon-color: #003E5E;
  --mdc-radio-selected-focus-icon-color: #003E5E;
  --mdc-radio-selected-hover-icon-color: #003E5E;
  --mdc-radio-selected-pressed-icon-color:#003E5E;
  --mat-mdc-radio-ripple-color: #003E5E;
  --mat-mdc-radio-checked-ripple-color: #003E5E;
}

.mat-radio-label {
  opacity: 1;
  font-size: 14px;
}

.mat-radio-outer-circle {
  border-width: 1.25px !important;
  border-color: #003E5E;
}

.mat-radio-inner-circle {
  background-color: #003E5E !important;
}

.mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.58) !important;
}
.mat-radio-checked .mat-radio-outer-circle {
  border-color: #003E5E !important;
}

.radio-block .mat-radio-button {
  display: block !important;
}

.mat-mdc-radio-button {
  display: inline-block;
}

.mdc-form-field > label {
  white-space: nowrap;
  font-family: "Maersk Text";
  font-size: 14px;
  padding-bottom: 2px;
  padding-left: 6px !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0 !important;
}

.radio-flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mdc-form-field :hover {
  cursor: pointer;
}

.ng-typeahead {
  position: relative;
  padding: 4px;
}
.ng-typeahead + .dropdown-menu {
  position: absolute;
  width: 280px;
  background: #FFFFFF;
  border: 1px solid #69B8D6;
  z-index: 10;
}
.ng-typeahead + .dropdown-menu .dropdown-item {
  width: 100%;
  background: #FFFFFF;
  color: #69B8D6;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #69B8D6;
  list-style: none;
}

.dialogue {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.dialogue_content-box {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background: #ffffff;
}
.dialogue_content-box_header {
  background: #EDEDED;
  padding: 20px;
  font-weight: bold;
}
.dialogue_content-box_content {
  padding: 30px 20px;
}
.dialogue_content-box_content--warning {
  color: red;
}
.dialogue_content-box_prompt-action {
  background: #EDEDED;
  padding: 20px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 16px;
}

nav {
  padding: 16px 16px 16px 16px;
  color: #2196F3;
}
nav .user-label {
  font-size: 23px;
  color: #444444;
  vertical-align: top;
}
nav .logout-button {
  font-size: 23px;
}
nav .img-offset {
  margin-left: 80px;
}
nav .role-lable {
  font-size: 16px;
  color: #444444;
  vertical-align: top;
}

.error-div {
  margin-top: 8px;
  background: #f8d7da;
  border: 1px solid #D0021B;
  padding: 8px;
  display: flex;
  align-items: center;
}
.error-div--error-msg-link {
  font-weight: bold;
  cursor: pointer;
}

.info-div {
  margin-top: 8px;
  background: #f8f0d8;
  border: 1px solid #F5A623;
  padding: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.info-panel {
  padding-top: 2px;
  color: #F5A623;
}
.info-panel .fa {
  font-size: 24px;
}

.error-panel {
  padding-top: 2px;
  color: #D0021B;
}
.error-panel .fa {
  font-size: 24px;
}

.info {
  margin-top: 8px;
  padding: 14px;
  align-items: center;
}
.info__error {
  background: rgba(245, 64, 58, 0.1);
  border: 1px solid #F5403A;
}
.info--error {
  color: #D0021B;
  font-size: 16px;
}
.info__warn {
  background: #f8f0d8;
  border: 1px solid #F5A623;
  display: flex;
  color: #F5A623;
}
.info__success {
  background: rgba(139, 182, 21, 0.1);
  border: 1px solid #A3A3A3;
}
.info__success--icon {
  color: #8BB615;
  font-size: 16px;
}
.info__icon {
  font-size: 24px;
  margin: 4px 16px 0 0;
}
.info__close {
  padding: 6px 8px;
  border-radius: 20px;
  cursor: pointer;
}
.info__close--error {
  background: #69B8D6;
  color: #FFFFFF;
  display: inline-flex;
}
.info__curtailment {
  margin-bottom: 5px;
}

.highlight-error {
  color: #D0021B;
}

.footer__copyright {
  margin: 24px 0;
}

.tile {
  width: 240px;
  border: 1px solid #737373;
  background-color: #FFFFFF;
  text-align: center;
  margin: 0 auto;
}
.tile__main {
  position: relative;
  text-align: center;
  height: 130px;
  border-bottom: 1px solid #737373;
}
.tile__image {
  max-height: 100%;
  max-width: 100%;
}
.tile__header {
  height: 100px;
  width: 200px;
  position: absolute;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -50px;
}
.tile__text {
  margin-top: -50px;
}
.tile__link {
  text-align: center;
  padding: 16px 0;
}
.tile__link > a {
  color: #003E5E;
  cursor: pointer;
}

.drawer-container {
  min-height: 100vh;
}

.drawer-handle {
  position: fixed;
  bottom: 24px;
}
.drawer-handle > .mat-raised-button {
  min-width: 48px;
}

.mat-drawer-inner-container {
  width: 100px !important;
}

.drawer-sidenav.mat-drawer {
  background: #003E5E;
  position: fixed;
}
.drawer-sidenav__menu {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  position: fixed;
}
.drawer-sidenav__menu__item__button {
  color: #FFFFFF;
  background: transparent;
  border: none;
  width: 100%;
  padding: 16px;
}
.drawer-sidenav__menu__item__button:hover {
  color: #69B8D6;
}
.drawer-sidenav__menu__item__button__active {
  color: #003E5E;
  background: #FFFFFF;
  opacity: 0.6;
}
.drawer-sidenav__menu__item__icon {
  font-size: 72px;
  line-height: 72px;
}
.drawer-sidenav__menu__item__text {
  text-align: center;
}

.feedback {
  background: #EDEDED;
}
.feedback__content {
  padding: 30px;
}
.feedback__content--align-right {
  text-align: right;
}
.feedback__content-button {
  padding: 0 30px 30px 30px;
}
.feedback textarea {
  resize: none;
  height: 220px;
  width: 100%;
}

.video {
  background: #FFFFFF;
  height: 300px;
  width: 530px;
}

.videoblock {
  background-color: grey;
  margin: 30px;
  width: 70%;
}

div.item {
  vertical-align: top;
  display: inline-block;
  text-align: center;
}

.caption {
  display: block;
  background-color: white;
}

/**
* This is Override CSS, which mainly focus on overriding custom styles provided by Angular Materialized Design
* Author - vso040
* this CSS may contain !important to force override the styles to align with the maersk themes
*/
.mat-expansion-indicator::after {
  border-width: 0px 3px 3px 0 !important;
  padding: 6px !important;
  color: #FFFFFF !important;
}

.mat-content {
  padding: 10px 20px;
}

.mat-expansion-panel {
  -moz-box-shadow: none !important;
  -ms-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.mat-expansion-panel-header {
  background: #003E5E;
  padding: 0 24px 0 0 !important;
  color: #FFFFFF;
  transition: all 1s ease-in-out;
}
.mat-expansion-panel-header label {
  color: #FFFFFF;
  opacity: 1;
}

.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
  background: #003E5E !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #003E5E !important;
}

.mat-expansion-panel-header:not(.mat-expanded) {
  background: #69B8D6;
}

.mat-expansion-panel-header-description {
  display: block !important;
  color: #FFFFFF;
  margin-top: 14px;
}

.mat-expansion-indicator {
  display: none;
}

.mat-icon {
  height: auto !important;
  width: auto !important;
  cursor: pointer;
}
.mat-icon > .fa {
  font-size: 24px;
  margin-right: 24px;
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: #FFFFFF;
}

a {
  color: #69B8D6;
  cursor: pointer;
}

a.disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: #535353;
  border-bottom: none !important;
}

.clickable {
  cursor: pointer;
}

.model {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}
.model .alternate-row {
  background-color: #CCCCCC;
}
.model__box {
  position: absolute;
  max-height: 90%;
  border: 1px solid #737373;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  max-width: 90%;
  background: #FFFFFF;
  overflow-y: auto;
}
.model__box__header {
  padding: 18px;
  font-size: 20px;
  font-weight: bold;
  color: #313131;
  letter-spacing: 0.05px;
  line-height: 28px;
}
.model__box__info {
  max-width: 500px;
  padding: 0 18px;
  margin: 0 0 calc(40px / 2) 0;
  line-height: 20px;
  letter-spacing: -0.1px;
}
.model__box__options {
  padding: 0 18px;
}
.model__box__content {
  overflow-y: auto;
  padding: 0 18px;
  line-height: 20px;
}
.model__box__content__radio-container {
  padding: 18px;
  background: #EDEDED;
  margin: 18px 0;
}
.model__box__content--fixed-height {
  max-height: 400px;
}
.model__box__content--radio-label {
  display: table-cell;
}
.model__box__content__reason {
  padding: 5px;
  letter-spacing: -0.1px;
}
.model__box__content--padding-large {
  padding: 0 65px;
}
.model__box__content--padding-normal {
  padding: 18px;
}
.model__box__content--fixed {
  width: 56px;
}
.model__box__error {
  padding: 0 18px;
}
.model__box__prompt-action {
  padding: 18px;
}

.quantity-selector {
  width: 100%;
  position: relative;
}
.quantity-selector input[type=text] {
  text-align: center;
  background-color: #FFFFFF;
}
.quantity-selector__increamenter {
  color: #282828;
  position: absolute;
  padding: 10px;
  font-size: 30px;
  top: 0;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.quantity-selector__increamenter--disabled {
  color: #CCCCCC;
}
.quantity-selector__subtraction {
  left: 0;
}
.quantity-selector__addition {
  right: 0;
}

.small-size-numeric-box {
  width: 50% !important;
}

.small-size-numeric-box-top {
  top: -10px;
}

.jumbo-box {
  background: #69B8D6;
  padding: 24px;
  font-size: 18px;
}

.customerDetails {
  background-color: #69B8D6;
}
.customerDetails__label {
  color: #003E5E;
}
.customerDetails__label-text {
  color: #003E5E;
}

.customerDetailsSearch {
  background-color: #69B8D6;
  max-height: 100%;
  padding: 16px;
}
.customerDetailsSearch__label {
  padding: 8px;
}
.customerDetailsSearch__byNameOption {
  float: right;
}
.customerDetailsSearch__searchResultsHeader {
  background-color: #003E5E;
  height: 29px;
  color: white;
  font-weight: bold;
}
.customerDetailsSearch__searchResults {
  border-bottom: 1px solid #003E5E;
  padding-bottom: 16px;
  padding-top: 16px;
}
.customerDetailsSearch__searchResults--status-active {
  color: #003E5E;
  font-weight: bold;
}
.otherStatus {
  color: #D0021B;
}

.customerSearchHeaders {
  font-size: 14px !important;
  font-weight: 600;
  opacity: 0.4;
}

.emptyValue {
  padding-left: 48px;
}

.heading {
  font-weight: bold;
  font-size: 30px;
}

.sub-heading {
  font-weight: bold;
  font-size: 19px;
  margin-top: 20px;
}

.customer__list {
  border-bottom: none;
}
.customer__list__label {
  color: #003E5E;
  opacity: 1;
}
.customer__list:nth-child(odd) {
  background-color: #FFFFFF;
}

.mat-snack-bar-container {
  height: 48px;
  min-width: 344px;
  padding-left: 16px;
  background: #000000;
  opacity: 0.87;
  border-radius: 0.25rem !important;
}

.file-upload-box {
  height: 150px;
  width: 600px;
  border: 2px dashed gray;
  display: table-cell;
  vertical-align: middle;
}
.file-upload-box__content {
  margin: auto;
  width: 150px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper input[type=file] {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.upload-btn-wrapper input[type=file]::-webkit-file-upload-button {
  cursor: pointer;
}

.remove-file-icon {
  font-size: 24px;
  color: #69B8D6;
  cursor: pointer;
}

.fa-download-icon {
  font-size: 16px;
  color: #69B8D6;
}

.draft {
  color: #CCCCCC !important;
}

.pending {
  color: #F5A623 !important;
}

.confirmed {
  color: #7ED321 !important;
}

.rejected {
  color: #D0021B !important;
}

.counter-offered {
  color: #F5A623 !important;
}

.expired {
  color: #CCCCCC !important;
}

.cancelled {
  color: #D0021B !important;
}

.renegotiation {
  color: #F5A623 !important;
}

.curtailment {
  color: #F5A623 !important;
}

.status {
  padding: 16px;
  border: 2px solid #313131;
  color: #313131;
  text-transform: uppercase;
  line-height: 19px;
  font-weight: bold;
}
.status__draft-box {
  background-color: #CCCCCC !important;
}
.status__pending-box {
  background-color: #F8E71C !important;
}
.status__confirmed-box {
  background-color: #7ED321 !important;
}
.status__rejected-box {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status__counter-offered-box {
  background-color: #F8E71C !important;
}
.status__expired-box {
  background-color: #CCCCCC !important;
  color: #FFFFFF;
}
.status__cancelled-box {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status__renegotiation-box {
  background-color: #F8E71C !important;
}
.status__curtailment-box {
  background-color: #F8E71C !important;
}
.status__pending {
  color: #F5A623 !important;
}
.status__confirmed {
  color: #7ED321 !important;
}
.status__renegotiation {
  color: #F5A623 !important;
}
.status__counter-offered {
  color: #F5A623 !important;
}

.status-od {
  margin-top: -14px;
  margin-left: 10px;
}
.status-od__draft-box {
  background-color: #CCCCCC !important;
}
.status-od__pending-box {
  background-color: #F8E71C !important;
}
.status-od__confirmed-box {
  background-color: #7ED321 !important;
}
.status-od__rejected-box {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status-od__counter-offered-box {
  background-color: #F8E71C !important;
}
.status-od__expired-box {
  background-color: #CCCCCC !important;
  color: #FFFFFF;
}
.status-od__cancelled-box {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status-od__renegotiation-box {
  background-color: #F8E71C !important;
}
.status-od__curtailment-box {
  background-color: #F8E71C !important;
}

.status-od {
  padding: 16px;
  color: #313131;
  text-transform: uppercase;
  line-height: 19px;
  font-weight: bold;
  font-size: 23px;
}

.status-accelerate {
  padding: 8px;
  color: #313131;
  text-transform: uppercase;
  font-weight: bold;
}
.status-accelerate__pending--background-color {
  background-color: #F8E71C !important;
}
.status-accelerate__confirmed--background-color {
  background-color: #7ED321 !important;
}
.status-accelerate__rejected--background-color {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status-accelerate__cancelled--background-color {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status-accelerate__counter_offered--background-color {
  background-color: #F8E71C !important;
}

.status-flexHub {
  padding: 8px;
  color: #313131;
  text-transform: uppercase;
  font-weight: bold;
}
.status-flexHub__pending--background-color {
  background-color: #F8E71C !important;
}
.status-flexHub__confirmed--background-color {
  background-color: #7ED321 !important;
}
.status-flexHub__rejected--background-color {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status-flexHub__cancelled--background-color {
  background-color: #D0021B !important;
  color: #FFFFFF;
}
.status-flexHub__counter_offered--background-color {
  background-color: #F8E71C !important;
}

.chips {
  background: #69B8D6;
  padding: 4px 4px 4px 16px;
  border-radius: 16px;
  color: #FAFAFA;
  display: inline-flex;
  margin: 4px;
}
.chips__close {
  margin-left: 8px;
  background: #FAFAFA;
  color: #69B8D6;
  border-radius: 16px;
  padding: 0 7px;
  cursor: pointer;
}
.chips__without-close .chips__close {
  display: none;
}
.chips__without-close .chips__content {
  padding-right: 12px;
}
.chips__label {
  padding-left: 12px;
  white-space: nowrap;
}

.combo-box {
  position: relative;
}
.combo-box__arrow {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 8px 24px 8px 8px;
  font-size: 24px;
  background: #FFFFFF;
  cursor: pointer;
}
.combo-box__content {
  min-width: 100%;
  position: absolute;
  z-index: 100;
  background: #FFFFFF;
}
.combo-box__content--full {
  width: 100%;
}
.combo-box__content > .suggestion-item {
  max-height: 136px;
}
.combo-box__content > .suggestion-item > ul > li:hover {
  background: #69B8D6;
}
.combo-box__content > .select-all {
  overflow: hidden;
}
.combo-box__content > .selected-item {
  max-height: 100px;
}
.combo-box__item {
  border: 1px solid #A3A3A3;
  overflow-y: auto;
  overflow-x: hidden;
}
.combo-box__item > ul {
  list-style: none;
}
.combo-box__item > ul > li {
  padding: 5px 16px;
}
.combo-box__item--remove-border {
  border: 0;
}
.combo-box__selected-header {
  font-size: 12px;
  padding: 0 16px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #003E5E;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}

.remove-icon {
  padding-top: 16px;
  color: #003E5E;
}
.remove-icon .fa {
  font-size: 30px;
}

.remove-origin-destination-icon {
  padding-top: 35px;
  color: #003E5E;
}
.remove-origin-destination-icon .fa {
  cursor: pointer;
  font-size: 22px;
}

.release {
  background: #FFFFFF;
  border: #A3A3A3 solid;
}
.release__content {
  padding: 5px;
}
.release__content--align-right {
  text-align: right;
}
.release__latest {
  pointer-events: none;
}
.release__description {
  border-left: 2px solid #EEEEEE;
}

.download-request-details-bar {
  margin-left: 0px;
  height: 75px;
  width: 80%;
  background: linear-gradient(180deg, #FFD44F 0%, #FF9100 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  transform: translate(-50%, 0);
}
.download-request-details-bar--links {
  padding-left: 4%;
}
.download-request-details-bar--reqNumberBox {
  background-color: #003E5E;
  text-align: center;
  margin-top: -8px;
}
.download-request-details-bar--text {
  color: #003E5E;
  font-size: 16px;
}
.download-request-details-bar--number {
  color: #FFFFFF;
  font-size: 16px;
}
.download-request-details-bar a {
  border-bottom: 2px solid #003E5E;
  text-decoration: none;
}
.download-request-details-bar--disable {
  cursor: not-allowed;
  color: #535353;
  border-bottom: none !important;
  pointer-events: none;
}
.download-request-details-bar--right-border {
  border-right: 1px solid #535353;
}
.download-request-details-bar--messages {
  white-space: nowrap;
  display: block;
  width: 530px;
  text-align: center;
  line-height: 30px;
}

.mat-date-picker .mat-form-field {
  width: 100% !important;
}
.mat-date-picker .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-input-text {
  width: 100%;
}

.compliance {
  min-width: 1000px;
  max-width: 1000px;
  min-height: 700px;
  overflow-y: auto;
}
.compliance__close {
  float: right;
  cursor: pointer;
  font-size: 16px;
}
.compliance__label--small {
  font-size: 12px;
}
.compliance__bigText-in-percentage {
  font-size: 24px;
  color: #006400;
  font-weight: bold;
}
.compliance__bigText-in-FFE {
  font-size: 24px;
  color: #69B8D6;
  font-weight: bold;
}
.compliance__current-week-bar {
  box-sizing: border-box;
  height: 520px;
  width: 2px;
  border: 1px solid #4A4A4A;
  position: fixed;
  top: 220px;
}
.compliance__current-week-lable {
  margin-left: -120px;
  margin-top: -10px;
}
.compliance__container {
  width: 950px;
}
.compliance__container .summary-container__label {
  width: 75px;
}
.compliance__container .summary-container__content {
  width: 875px;
}

.flex-container {
  display: flex;
}

.flex-container > div {
  padding: 10px;
  font-size: 14px;
}

.logout {
  position: relative;
  width: 100%;
}
.logout__container {
  position: absolute;
  border: 1px solid #A3A3A3;
  padding: 16px;
  background: #FFFFFF;
  width: 100%;
}
.logout__container__info {
  color: #282828;
}

.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
  top: 2.5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggleSlider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .toggleSlider {
  background-color: #64b2d4;
}

input:focus + .toggleSlider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .toggleSlider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.toggleSlider.round {
  border-radius: 38px;
}

.toggleSlider.round:before {
  border-radius: 50%;
}

.custom-lable {
  margin-right: 8px;
}

.floating-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.floating-panel__box {
  padding: 8px;
  border: 1px solid #003E5E;
  margin: 8px;
  border-radius: 8px;
  background: #FFFFFF;
}
.floating-panel__box--min-width {
  min-width: 100px;
}
.floating-panel__box--disabled {
  background: #F7F7F7 !important;
  border: 1px solid #F7F7F7;
  box-shadow: none !important;
}
.floating-panel__box--disabled:hover {
  background: #F7F7F7;
  color: #F7F7F7;
}
.floating-panel__box--disabled a {
  color: #999999 !important;
}
.floating-panel__checkbox {
  padding: 4px;
  border: 1px solid #003E5E;
  margin: 8px;
  border-radius: 8px;
  background: #FFFFFF;
}

.mat-tab-label, .mat-ink-bar {
  width: 50%;
}

.datepicker {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 296px;
  height: 354px;
}

.date-validity {
  width: 100%;
}
.date-validity .datepicker {
  background: #FFFFFF;
}
.date-validity .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.validity-margin {
  margin-top: 28px;
}

.product {
  overflow: hidden;
  background-color: #F7F7F7;
  border: 1px solid #CCCCCC;
}
.product__view-port {
  overflow-x: auto;
  overflow-y: hidden;
  width: 1108px;
  margin: 0 auto;
}
.product__view-port::-webkit-scrollbar {
  height: 6px;
}
.product__view-port::-webkit-scrollbar-track {
  background-color: #69B8D6;
}
.product__view-port::-webkit-scrollbar-thumb {
  background-color: #003E5E;
  border-radius: 8px;
}
.product__view-port__reel {
  min-width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
}
.product__view-port__reel__fixed {
  height: 100px;
}
.product__view-port__reel__fixed--offset {
  padding: 8px;
}
.product__view-port__reel__fixed--lbl {
  white-space: nowrap;
}
.product__view-port__reel__fixed .floating-panel__box {
  padding: 0;
  margin: calc(8px / 2);
  box-shadow: 0 0 8px calc(-1 * (8px / 2)) #000000;
}
.product__view-port__reel__fixed .floating-panel__box__vertical-anchor {
  height: 100%;
  width: 100%;
  display: table;
}
.product__view-port__reel__fixed .floating-panel__box__vertical-anchor a {
  font-size: calc(24px / 2);
  line-height: 14px;
  padding: 2px;
  display: table-cell;
  vertical-align: middle;
  color: #282828;
}
.product__view-port__reel__fixed .floating-panel__box__vertical-anchor a:hover {
  color: #FFFFFF;
}
.product__view-port__reel__fixed .floating-panel__box:hover {
  background-color: #003E5E;
}
.product__view-port__reel__fixed--view {
  position: absolute;
  width: calc(100% - 30px);
}
.product__view-port__reel__fixed--view .buffer-width {
  width: 280px;
}
.product__view-port__reel__fixed__lbl-ffe, .product__view-port__reel__fixed__ffe-input {
  text-align: left;
}
.product__view-port__reel__scrollable--offset {
  padding: calc(24px / 2) 0;
}
.product__view-port__reel__scrollable__allocation input[type=text], .product__view-port__reel__scrollable__allocation input[type=number] {
  width: 90%;
}
.product__view-port__reel__calender {
  background-color: #003E5E;
  color: #FFFFFF;
}
.product__view-port__reel__quarter {
  position: relative;
}
.product__view-port__reel__quarter::before {
  content: "";
  width: 100%;
  height: 1px;
  background: #282828;
  display: block;
  position: absolute;
  top: 50%;
}
.product__view-port__reel__quarter--back {
  z-index: 100;
  border-right: 1px solid #282828;
}
.product__view-port__reel__quarter--offset {
  padding: 0 8px;
  background: #FFFFFF;
  font-size: 16px;
}
.product__view-port__reel--line {
  box-shadow: 0 0 8px calc(-1 * (8px / 2)) #000000;
}
.product__view-port__reel--line2 {
  box-shadow: 0 0 8px -8px #000000;
}
.product__view-port__reel--left {
  justify-content: flex-start;
}
.product__view-port input[type=text], .product__view-port input[type=number] {
  padding: calc(8px / 2);
  border: 1px solid #CCCCCC;
  font-family: "Maersk Text";
  font-size: 14px;
  text-align: center;
}
.product__view-port .col-1 {
  text-align: center;
}
.product__guides {
  border-left: 1px solid #CCCCCC;
  border-right: 1px solid #CCCCCC;
}

.product-details {
  box-shadow: 0 0 8px -8px #000000;
}
.product-details:nth-child(odd) {
  background: #FFFFFF;
}

.tooltip {
  position: absolute;
}
.tooltip__arrow {
  position: absolute;
  left: 8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #003E5E;
}
.tooltip__content {
  box-shadow: 0 0 2px -1px #000000;
  background: #003E5E;
  padding: 0 2px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #003E5E;
  margin-top: 5px;
  color: #FFFFFF;
}

.od-slider__view-port {
  width: 200%;
}

.place-holder {
  position: absolute;
  background: #EDEDED;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  z-index: 10;
}

.form_element {
  background-color: #F7F7F7;
  padding: 16px;
  border: 1px solid #CCCCCC;
  position: relative;
}
.form_element__label {
  position: absolute;
  top: -14px;
  background: white;
  padding: 0 8px 0 8px;
  opacity: 1;
}
.form_element__content {
  margin-top: 8px;
}

.exceptionModel {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  overflow-y: scroll;
  overflow-x: hidden;
}
.exceptionModel__box {
  position: absolute;
}
.exceptionModel__input {
  width: 70px;
}
.exceptionModel__div {
  background-color: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(121, 121, 121);
  border-radius: 5px;
  padding: 5px;
}
.exceptionModel__text {
  position: absolute;
  width: 100%;
  word-wrap: break-word;
}
.exceptionModel__text__summary {
  bottom: 80px;
  left: 30px;
}
.exceptionModel__text__action-msg {
  bottom: 30px;
  left: 10px;
}
.exceptionModel__btn {
  position: absolute;
  bottom: 10px;
}
.exceptionModel__btn__no {
  left: 50px;
}
.exceptionModel__btn__confirm {
  left: 150px;
}

.custom-add {
  display: table-cell;
}

.ng-tooltip {
  position: absolute;
  max-width: 300px;
  font-size: 14px;
  text-align: center;
  color: #282828;
  padding: 3px 8px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 0.5px solid #999999;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  box-shadow: -2px -2px 3px #999999;
}
.ng-tooltip-show {
  opacity: 1;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-bottom:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  margin-left: -5px;
  left: 49.5px;
  border: 10px solid transparent;
  border-bottom-color: #282828;
}
.ng-tooltip-bottom:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50px;
  margin-left: -5px;
  border: 9.5px solid transparent;
  border-bottom-color: #FFFFFF;
}
.ng-tooltip-top:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  margin-left: -5px;
  left: 49.5px;
  border: 10px solid transparent;
  border-bottom-color: #282828;
}
.ng-tooltip-top:after {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 50px;
  margin-left: -5px;
  border: 9.5px solid transparent;
  border-bottom-color: #FFFFFF;
}
.ng-tooltip-left:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border: 9.5px solid transparent;
  border-bottom-color: #FFFFFF;
}
.ng-tooltip-right:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border: 9.5px solid transparent;
  border-bottom-color: #FFFFFF;
}
.ng-tooltip td {
  padding-right: 20px;
  text-align: left;
}
.ng-tooltip td:last-child {
  padding-right: 0.5px;
}

.sales_feedback_modal__header-font {
  font-weight: bold;
}
.sales_feedback_modal__close-button {
  cursor: pointer;
}
.sales_feedback_modal--modal-box .model__box {
  min-width: 1000px;
  margin: 0 auto;
}

.service_contract_grid__content {
  max-width: 100%;
}
.service_contract_grid__label {
  max-width: 70%;
}
.service_contract_grid__row_height {
  min-height: 58px;
}

.od-history__section {
  outline: thin solid;
  padding: 20px;
  margin-bottom: 15px;
}
.od-history__alternate {
  background-color: #EDEDED;
}

.weekly-breakdown-grid__content {
  max-width: 100%;
}
.weekly-breakdown-grid__label {
  max-width: 70%;
}
.weekly-breakdown-grid__row_height {
  min-height: 58px;
}
.weekly-breakdown-grid__header-element {
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  line-height: 32px;
  width: 116px;
  cursor: pointer;
}
.weekly-breakdown-grid__header-element--active {
  background-color: #69B8D6;
  color: #FFFFFF;
}
.weekly-breakdown-grid__header-element--arrow {
  font-size: 32px;
  cursor: pointer;
}

.quarter-navigator-container {
  display: flex;
  align-items: center;
}
.quarter-navigator-container .quarter-arrow {
  font-size: 48px;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
.quarter-navigator-container .quarter-arrow--disabled {
  color: grey;
}

.quarter-dial-window {
  position: relative;
  margin: 0 auto;
  width: 300px;
  height: 80px;
  overflow: hidden;
}

.quarter-dial {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 800ms ease-in-out;
}
.quarter-dial .item {
  width: 100px;
  padding: 20px;
  text-align: center;
  color: inherit;
}
.quarter-dial .item__dummy {
  color: #A3A3A3;
}
.quarter-dial .item .quarter {
  transition: all 800ms ease-in-out;
}
.quarter-dial .item .quarter__active {
  color: #69B8D6;
  transform: scale(2);
}
.quarter-dial .item .year {
  transition: color 800ms ease-in-out;
}
.quarter-dial .item .year__active {
  color: #69B8D6;
}

.calendar-state.mat-expansion-panel .mat-expansion-panel-header {
  cursor: default;
  background: transparent;
  height: 0px !important;
}

.calendar-state.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: transparent !important;
}

.calendar-state.mat-expansion-panel .mat-expansion-panel-header:focus {
  background: transparent !important;
}
.calendar-state .mat-expansion-panel-content {
  user-select: none;
}
.calendar-state .mat-expansion-panel-body {
  padding: 0px;
}
.calendar-state .committed {
  display: none;
}

.mat-tooltip.radio-tooltip {
  background-color: #003E5E;
  font-size: 12px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.mat-tooltip.span-tooltip {
  background-color: #003E5E;
  font-size: 12px;
  margin-top: 3px;
}

.accelerate-container {
  display: flex;
  overflow-x: auto;
  width: 1072px;
  padding: 8px 8px;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
}
.accelerate-container .volume-label {
  width: 165px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-right: 8px;
  padding-top: 80px;
}
.accelerate-container .accelerate-window {
  width: 100%;
  overflow-x: auto;
  position: relative;
}
.accelerate-container .accelerate-window .quarter-range-reel {
  position: relative;
  height: 24px;
}
.accelerate-container .accelerate-window .quarter-range-reel .line {
  position: absolute;
  top: 50%;
  border-top: 2px solid;
  width: 100%;
}
.accelerate-container .accelerate-window .week-number-reel {
  height: 56px;
  background-color: #003E5E;
}
.accelerate-container .accelerate-window .week-volume-reel {
  background-color: white;
}
.accelerate-container .accelerate-window .quarter-container {
  top: 0px;
  position: absolute;
  display: flex;
}
.accelerate-container .accelerate-window .quarter-container .quarter {
  position: relative;
  height: 24px;
  border-right: 1px solid;
}
.accelerate-container .accelerate-window .quarter-container .quarter__label {
  text-align: center;
  width: 79px;
  margin: auto;
  position: relative;
  background-color: white;
}
.accelerate-container .accelerate-window .quarter-container .week-container {
  display: flex;
  padding-top: 8px;
}
.accelerate-container .accelerate-window .quarter-container .week-container__item {
  width: 80px;
  color: white;
  text-align: center;
}
.accelerate-container .accelerate-window .volume-container {
  display: flex;
  padding: 8px 0px;
}
.accelerate-container .accelerate-window .volume-container__item {
  width: 80px;
  text-align: center;
}
.accelerate-container .accelerate-window::-webkit-scrollbar {
  height: 8px;
}
.accelerate-container .accelerate-window::-webkit-scrollbar-thumb {
  background-color: #003E5E;
  border-radius: 8px;
}
.accelerate-container .accelerate-window::-webkit-scrollbar-track {
  background-color: #69B8D6;
}

.accelerate-view .mat-expansion-indicator {
  display: block;
}
.accelerate-view .mat-expansion-panel-header {
  background: #ffffff;
}
.accelerate-view .mat-expansion-indicator::after {
  color: #000000 !important;
}
.accelerate-view .mat-expansion-panel-header.mat-expanded:focus, .accelerate-view .mat-expansion-panel-header.mat-expanded:hover {
  background: #ffffff !important;
}
.accelerate-view .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #ffffff !important;
}

.flexhub-view .mat-expansion-indicator {
  display: block;
}
.flexhub-view .mat-expansion-panel-header {
  background: #ffffff;
}
.flexhub-view .mat-expansion-indicator::after {
  color: #000000 !important;
}
.flexhub-view .mat-expansion-panel-header.mat-expanded:focus, .flexhub-view .mat-expansion-panel-header.mat-expanded:hover {
  background: #ffffff !important;
}
.flexhub-view .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: #ffffff !important;
}
.flexhub-view .mat-expansion-panel-content {
  line-height: 24px;
}

.alert {
  padding: 20px;
  background-color: skyblue;
  color: black;
  border-radius: 8px;
  margin: 8px;
  font-weight: normal;
  font-size: 14px;
}

.alert-msg {
  background-color: skyblue;
  color: black;
  border-radius: 8px;
  margin: 8px;
}

.accelerate_od_panel {
  max-height: 200px;
  overflow: auto;
}

.warning-msg {
  display: inline-block;
  padding-left: 42px;
}

.mat-mdc-icon-button {
  padding: 0 !important;
  min-width: 0 !important;
  width: 40px !important;
  height: 40px !important;
}

.mdc-icon-button {
  white-space: nowrap;
}

.node-tree {
  background: white;
  overflow-y: scroll;
  height: 300px;
}

.node-tree-invisible {
  display: none;
}

.node-tree ul,
.node-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.node-tree ul {
  margin-left: 24px;
}

.od-group-tree {
  border: 1px solid #B2B2B2;
  padding: 16px;
  background: white;
  border-radius: 4px;
  overflow-x: auto;
}
.od-group-tree .odg-search {
  position: relative;
}
.od-group-tree .odg-search .odg-search__icon {
  position: absolute;
  right: 15px;
  top: 15px;
}
.od-group-tree .odg-search__input {
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 2px solid #CCCCCC;
  border-radius: 6px;
}
.od-group-tree .mat-tree-node-text {
  margin-left: 15px;
  font-family: "Maersk Text";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #141414;
}

.od-store-door {
  border: 1px solid #B2B2B2;
  padding: 16px;
  background: white;
  border-radius: 4px;
}

.od-nap {
  border: 1px solid #B2B2B2;
  padding: 16px;
  background: white;
  border-radius: 4px;
}

.valid-odpairs {
  max-height: 350px;
  overflow: auto;
}

.mdc-tab {
  --mdc-typography-button-font-family: "Maersk Text";
  --mdc-typography-font-family: "Maersk Text";
  --mdc-typography-button-font-size: 14px;
  --mdc-typography-button-line-height: 24px;
  --mdc-typography-button-font-weight: normal;
  --mdc-typography-button-letter-spacing: normal;
  --mdc-typography-button-text-decoration: none;
  --mdc-tab-indicator-active-indicator-color: #003E5E;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #003E5E;
}

.mat-mdc-tab-group {
  margin-top: 20px;
}

.pending-tray .hide {
  display: none;
}
.pending-tray .fixed-td {
  width: 160px;
}
.pending-tray .fixed-td-large-width {
  width: 170px;
}
.pending-tray .sort {
  padding: 0 0 0 5px;
}
.pending-tray .sort > .fa {
  display: block;
  cursor: pointer;
}
.pending-tray .sort--disabled {
  color: #A3A3A3;
}
.pending-tray .sort .sort-icon {
  width: 12px;
  height: 6px;
  background: url("/assets/img/sort_arrow.png") center center;
  background-size: cover;
  margin-bottom: 2px;
}
.pending-tray .sort .sort-icon-inverted {
  transform: rotate(180deg);
}
.pending-tray .pagination > .pagination-disabled {
  color: #A3A3A3;
}
.pending-tray .section-margin {
  margin: 20px 0;
}
.pending-tray .custom-add {
  display: table-cell;
  color: #FFFFFF;
  font-weight: bold;
}
.pending-tray .input-margin {
  margin: 20px 0;
}
.pending-tray td:first-child {
  border-right: 1px solid #A3A3A3;
}
.pending-tray th:first-child {
  border-right: 1px solid #282828;
}
.pending-tray tbody tr:hover {
  background-color: #EDEDED;
}
.pending-tray .filter-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 16px;
  row-gap: 32px;
}

.switch-view {
  padding: 10px;
  transition: all 0.5s ease-in-out;
}

.rotate90 {
  transform: rotate(90deg);
}

.pagingOptions {
  width: 72px;
}

.border-bottom {
  border-bottom: 2px solid #CCCCCC;
  padding-bottom: 24px;
}

.border-bottom_without_pad {
  border-bottom: 2px solid #CCCCCC;
}

.custom-btn-margin {
  margin: 0 0 0 20px !important;
}

.no-records-found {
  font-weight: inherit;
  font-size: 32px;
}

.status-td {
  width: 100px;
}

.toggleFlag--le::after {
  content: "≤";
  font-size: 2em;
}
.toggleFlag--gt::after {
  content: ">";
  font-size: 2em;
}

.contract-overview {
  background-color: #EDEDED;
}

.request-details-container {
  box-shadow: 0 0 4px -2px #313131;
  padding: 20px 20px 20px 20px;
}

.border-radius {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}

.mat-expansion-panel-header {
  height: auto !important;
}

.mat-content {
  padding: 20px;
}

.comment.mat-expansion-panel-header,
.comment.mat-expansion-panel-header:focus,
.comment.mat-expansion-panel-header.mat-expanded:focus,
.comment.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel:not(.mat-expanded) .comment.mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: none !important;
  color: #222222;
  font-size: 14px;
  opacity: 0.7;
  padding-right: 0 !important;
  pointer-events: none;
}

.comment.mat-expansion-panel {
  background: none !important;
}

.comment + .mat-expansion-panel-content {
  height: 0;
}

.comment .mat-content {
  flex-direction: column;
  padding: 0;
  word-break: break-word;
}

.comment a {
  pointer-events: auto;
}

.comment a.ng-star-inserted {
  color: #003E5E;
}

.jumbo-box #reason .comment a {
  color: #003E5E;
}

.week-count {
  height: 48px;
  display: inline-block;
  margin-left: 48px;
}
.week-count input[type=text] {
  text-align: center;
  color: #282828;
}

.week-count-input {
  width: 80px !important;
  height: 40px;
}

.manualValidationOption {
  margin-left: 600px;
}

.audit-box-modal .model__box {
  width: 70%;
}
.audit-box-modal .model__box .alternate-row {
  background-color: #CCCCCC;
}

.contract-overview .background {
  background-color: #EDEDED;
}
.contract-overview .margin {
  margin: 3px 0 0 0;
}

.contract-overview-width {
  flex-basis: 150% !important;
}

.include-exclude-radios .mat-radio-label-content {
  white-space: pre-wrap;
}

.mat-checkbox-layout {
  font-size: 14px;
  opacity: 1 !important;
}

.mat-checkbox-inner-container {
  display: inline-block;
  height: 18px !important;
  line-height: 0;
  margin: auto;
  margin-right: 8px;
  order: 0;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 18px !important;
  flex-shrink: 0;
}

.mat-checkbox-frame {
  background-color: transparent;
  transition: border-color 90ms cubic-bezier(0, 0, 0.2, 0.1);
  border-style: solid;
  border-width: 1.25px !important;
  border-color: #003E5E !important;
}

.product-calendar-multi-od {
  margin-top: 100px;
}

.product-control-panel-multi-od {
  margin-top: 16px;
}

.multi-od-scrollable-text, .view-all-od-dialog-text {
  font-weight: bold;
}

/* Following styles are applicable for multi od history */
.delete_requested .multi-origin-name, .deleted .multi-origin-name {
  color: black;
  text-decoration: line-through;
}
.delete_requested .multi-destination-name, .deleted .multi-destination-name {
  color: black;
  text-decoration: line-through;
}

/* Styles for multi od scroll element and input element */
.delete-requested-scroll, .input-location-delete-requested {
  color: black;
  text-decoration: line-through;
}

/* style for multi od input element */
.input-location-added {
  color: red;
}

/* OD cancel confirmed alert text when allocation has vas */
.cancel-confirmed-alert-text {
  color: red;
  font-size: 16px;
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-icon-color: #003E5E;
  --mdc-checkbox-selected-focus-icon-color: #003E5E;
  --mdc-checkbox-selected-hover-icon-color: #003E5E;
  --mdc-checkbox-selected-pressed-icon-color: #003E5E;
}

.create-request .section-separator-bottom {
  border-bottom: 2px solid #A3A3A3;
}
.create-request .display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.create-request .move {
  background: none;
  font-size: 2em;
  border: none;
}
.create-request .group-active {
  cursor: pointer;
  border-bottom: 2px solid #69B8D6;
}

.customer-search-button {
  padding-top: 35px;
}

.float-right {
  float: right;
}

.center-align {
  align-items: center;
}

.external-system-failure-box {
  background: #f8d7da;
  border: 1px solid #D0021B;
  padding: 8px;
}

.origin-element {
  width: 280px;
}

.destination-element {
  width: 280px;
}

.od-pair #allowed-lbl {
  color: #006400;
}

.success-text {
  color: #006400;
}

.od-pair #disallowed-lbl {
  color: #D0021B;
}

/* Multi od remove absolute invalid locations icon pointer */
.remove-absolute-invalid-locations {
  cursor: pointer;
}

.create-response .section-separator-bottom {
  border-bottom: 2px solid #A3A3A3;
}
.create-response .display-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.create-response .flex-elem-auto {
  flex: auto;
}
.create-response .success-save {
  border: 1px solid #A3A3A3;
  background-color: rgba(139, 182, 21, 0.1);
}
.create-response .success-submit {
  border: 1px solid #8BB615;
  background-color: rgba(139, 182, 21, 0.1);
}

.learn-more {
  border-left: 2px solid #EEEEEE;
}

.audit-trail {
  position: relative;
  z-index: 1;
}
.audit-trail .alternate-row {
  background-color: #CCCCCC;
}
.audit-trail__box {
  position: absolute;
  max-height: 600px;
  width: 100%;
  top: -32px;
  background-color: #FFFFFF;
  border: 1px solid;
  overflow: auto;
}
.audit-trail__box div {
  word-break: break-word;
}
.audit-trail__header-font {
  font-weight: bold;
}

.curtailment-action-bar {
  padding-bottom: 150px;
}
.curtailment-action-bar .action-bar {
  left: 5%;
  right: 5%;
  margin: 0 auto;
  min-height: 80px;
  background-color: #69B8D6;
  position: fixed;
  bottom: 0px;
  width: 85%;
  border: 1px solid;
  background: linear-gradient(180deg, #afd9e9 0%, #69B8D6 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.curtailment-action-bar .action-bar--border-right {
  border-right: 1px solid;
}
.curtailment-action-bar .action-bar--flex-box {
  align-items: flex-end;
}
.curtailment-action-bar .action-bar--floating-panel__box {
  border: 1px solid #003E5E;
  border-radius: 8px;
  background: #FFFFFF;
}
.curtailment-action-bar .action-bar--mat-date-picker .mat-form-field-wrapper {
  padding-bottom: 0px;
}
.curtailment-action-bar .action-bar--mat-date-picker .mat-form-field-infix {
  width: 110px;
}
.curtailment-action-bar .action-bar--mat-date-picker .mat-form-field-underline {
  height: 0px;
}
.curtailment-action-bar .action-bar--mat-date-picker .mat-input-element:disabled {
  color: #282828;
}
.curtailment-action-bar .action-bar--mat-date-picker .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.curtailment-action-bar .action-bar--mat-date-picker .mat-mdc-form-field-infix {
  width: 100%;
}
.curtailment-action-bar .action-bar .text-counter {
  opacity: 0.6;
}