.od-store-door {
    border: 1px solid #B2B2B2;
    padding: 16px;
    background: white;
    border-radius: 4px;
}

.od-nap {
    border: 1px solid #B2B2B2;
    padding: 16px;
    background: white;
    border-radius: 4px;
    
}

.valid-odpairs{
    max-height: 350px; 
    overflow: auto;

}