.customerDetails{
   background-color: $col-cust-div-background;
   &__label{
       color: $col-cust-div-label;
   }
   &__label-text{
       color: $col-cust-div-label-text;
   }       
}

.customerDetailsSearch{
   background-color: $col-cust-div-background;
   max-height: $percentage-full;
   padding: $spacer-small;
   &__label{
       padding: $spacer-tiny;
   }
   &__byNameOption{
       float: right;
   }
   &__searchResultsHeader{
       background-color: $col-cust-div-label;
       height: $spacer-medium + ($spacer-thin * 5);
       color: white; 
       font-weight: bold;
   }
  
   &__searchResults{       
       border-bottom: $spacer-thin solid $col-cust-div-label;
       padding-bottom: $spacer-small;
       padding-top: $spacer-small;
       &--status-active{
            color: $col-cust-div-label;
            font-weight: bold;
       }
       .label{
        @extend .font--default--bold;
       }       
   }
          
}

.otherStatus{
    color: $col-status-red;
}

.customerSearchHeaders{
    font-size: 14px !important;
    font-weight: 600;
    opacity: 0.4;
}

.emptyValue{
    padding-left: $spacer-medium * 2;
}

.heading{
    font-weight: bold;
    font-size: $heading-medium-margin + $spacer-thin + $spacer-thin;
}

.sub-heading {
    font-weight: bold;
    font-size: $heading-small - $spacer-thin;
    margin-top: $heading-medium-padding;
}

.customer{
    &__list{
        border-bottom: none;
        &__label{
            color: $col-cust-div-label;
            opacity: 1;
        }
        &:nth-child(odd){
            background-color: $white;
        }
    }
}