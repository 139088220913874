.tooltip{
    position: absolute;
    &__arrow{
        position: absolute;
        left: $spacer-tiny;
        border-left: $spacer-tiny solid transparent;
        border-right: $spacer-tiny solid transparent;
        border-bottom: $spacer-tiny solid $accent-secondary;
    }
    &__content{
        box-shadow: 0 0 2px -1px #000000;
        background: $accent-secondary;
        padding: 0 2px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid $accent-secondary;
        margin-top: 5px;
        color: #FFFFFF;
    }
}