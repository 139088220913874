nav{
    @include padding($spacer-small, $spacer-small, $spacer-small, $spacer-small);
    color: $link-default;
    .user-label{
        @extend .font--default--bold;
        font-size: $user-label-font-size;
        color: $col-user-label;
        vertical-align: top;
    }
    .logout-button{
        font-size: $user-label-font-size;
    }
    .img-offset{
        margin-left: 80px;
    }
    .role-lable{
        @extend .font--default--bold;
        font-size: $label-font-size;
        color: $col-user-label;
        vertical-align: top;
    }
}
