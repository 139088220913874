/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';

@font-face {
	font-family: 'Zeta Serif';
	src: url('../src/assets/fonts/maeu/zetta-serif-demibold.eot');
	src: url('../src/assets/fonts/maeu/zetta-serif-demibold.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/maeu/zetta-serif-demibold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Zeta Serif';
	src: url('../src/assets/fonts/maeu/zetta-serif-heavy.eot');
	src: url('../src/assets/fonts/maeu/zetta-serif-heavy.eot?#iefix') format('embedded-opentype'),
		url('../src/assets/fonts/maeu/zetta-serif-heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

.maersk-loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.maersk-loader img {
    width: 500px;
    height: 100px;
}
