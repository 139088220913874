input[type="text"], textarea{
    width: $percentage-full;
    padding: $input-padding-default;
    font-size: $input-font-size-default;
    border: $border-thick solid $grey6;
}

textarea {
    resize: none;
}

select{
    width: $percentage-full;
    font-size: $input-font-size-default;
    border: $border-thick solid $grey6;
    padding: 0 0 0 $input-padding-default;
}

.input-large{
    height: $height-large;
    border: $spacer-thin solid $border-dark !important; // important is added to override default styling on input
    @include box-shadow(calc(#{$spacer-tiny}/2), calc(#{-$spacer-tiny}/2), $shadow-black);
}

.input-error{
    border: $border-thick solid $red !important;
}

.input-default{
    height: $height-default;
}

.custom-search{
    position: relative;
    .input-search-icon{
        position: absolute;
        top: $label-font-size;
        right: $search-margin-right;
        font-size: 1.2em;
        color: $col-border-input;
    }
}

.textarea-default{
    height: $height-textarea;
    border: $border-thick solid $col-border-input; 
}

.input-small{
    // important is required here to overwright global width for input[text]
    //width: $input-box-width !important;
    height: $spacer-medium;
    border: $border-thick solid $grey6;
}

.input-highlight{
    border: $border-thick solid $accent-color !important;
}

.input-highlight-cav{
    background: $amber;
    border: $border-thick solid $blue;
}

.input-top-padding {
    position: relative;
    top: $top-margin;
    margin: 0px 0px 0px $anchor-margin;
}