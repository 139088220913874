.switch {
  position: relative;
  display: inline-block;
  width: $download-req-count-box-width;
  height: $font-size-header-secondary;
  top: $border-thick + $spacer-half;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: $toggleTransition;
  transition: $toggleTransition;
}

.toggleSlider:before {
  position: absolute;
  content: "";
  height: $font-size-default;
  width: $font-size-default;
  left: $mat-expansion-border-padding;
  bottom: $mat-expansion-border-padding;
  background-color: white;
  -webkit-transition: $toggleTransition;
  transition: $toggleTransition;
}

input:checked + .toggleSlider {
  background-color: #64b2d4;
}

input:focus + .toggleSlider {
  box-shadow: 0 0 $spacer-thin #2196F3;
}

input:checked + .toggleSlider:before {
  -webkit-transform: translateX($spacer-medium + $spacer-thin + $spacer-thin);
  -ms-transform: translateX($spacer-medium + $spacer-thin + $spacer-thin);
  transform: translateX($spacer-medium + $spacer-thin + $spacer-thin);
} 

/* Rounded sliders */
.toggleSlider.round {
  border-radius: $heading-medium + $spacer-tiny;
}

.toggleSlider.round:before {
  border-radius: $percentage-half;
}

.custom-lable {
  margin-right: $spacer-tiny;
}