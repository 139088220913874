/*  Map deep get
	@author Hugo Giraudel
	@access public
	@param {Map} $map - Map
	@param {Arglist} $keys - Key chain
	@return {*} - Desired value*/
@function map-deep-get($map, $keys...) {
	@each $key in $keys {
		$map: map-get($map, $key);
	}
	@return $map;
}