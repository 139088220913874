.release {
    background: $white;
    border:     $releasenote-border solid;   

    &__content{
        padding: $releaseupdate-content-padding;
        &--align-right{
            text-align: right;
        }
    }
    &__latest{
        pointer-events: none
    }
    &__description{
        border-left: 2px solid $bg-light;
    }
}