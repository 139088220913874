.mdc-tab {
  --mdc-typography-button-font-family: 'Maersk Text';
  --mdc-typography-font-family: 'Maersk Text';
  --mdc-typography-button-font-size: 14px;
  --mdc-typography-button-line-height: 24px;
  --mdc-typography-button-font-weight: normal;
  --mdc-typography-button-letter-spacing: normal;
  --mdc-typography-button-text-decoration: none;
  --mdc-tab-indicator-active-indicator-color: #003E5E;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #003E5E;
}

.mat-mdc-tab-group {
  margin-top: 20px;
}
