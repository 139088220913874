/**
* This is Override CSS, which mainly focus on overriding custom styles provided by Angular Materialized Design
* Author - vso040
* this CSS may contain !important to force override the styles to align with the maersk themes
*/
.mat-expansion-indicator::after {
    border-width: 0px $mat-expansion-border-padding $mat-expansion-border-padding 0 !important;
    padding: $mat-expansion-indicator-padding !important;
    color: $col-accordion-arrow !important;
}

.mat-content{
    padding: $mat-content-padding-vert $mat-content-padding-horz;
}

.mat-expansion-panel{
    -moz-box-shadow: none !important;
    -ms-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.mat-expansion-panel-header{
    background: $accent-secondary;
    padding: 0 $spacer-medium 0 0 !important;
    color: $col-accordion-header-font;
    label{
        color: $col-accordion-header-font;
        opacity: 1;
    }
    transition: all 1s ease-in-out;
}

.mat-expansion-panel-header.mat-expanded:focus,
 .mat-expansion-panel-header.mat-expanded:hover{
    background: $accent-secondary !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
    background: $accent-secondary !important;
}

.mat-expansion-panel-header:not(.mat-expanded){
     background: $accent-color;
}

.mat-expansion-panel-header-description{
    display: block !important;
    color: $col-accordion-header-font;
    margin-top: $mat-panel-header-padding-top;
}

.mat-expansion-indicator{
    display: none;
}

.mat-icon{
    height: auto !important;
    width: auto !important;
    cursor: pointer;
    @extend .font--default--bold;
    > .fa{
        font-size: $spacer-medium;
        margin-right: $spacer-medium;
    }
}

.mat-expansion-panel-header[aria-disabled=true] {
    color: $white;
}
