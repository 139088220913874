.curtailment-action-bar{
    padding-bottom: $curtail-action-bar-bottom-padding;
    .action-bar{
        left: $curtail-action-bar-left-right-margin;
        right: $curtail-action-bar-left-right-margin;
        margin: 0 auto;
        min-height: $curtail-action-bar-min-height;
        background-color: $col-action-bar;
        position: fixed;
        bottom: 0px;
        width: $curtail-action-bar-width;
        border: $spacer-thin solid;
        background: linear-gradient(180deg, $col-action-bar-bg-gradient 0%, $col-action-bar 100%);
        box-shadow: 0 $spacer-thin*2 $spacer-thin*4 0 rgba(0, 0, 0, 0.5);

        &--border-right{
            border-right: $spacer-thin solid;
        }
        
        &--flex-box{
            align-items: flex-end;
        }
        &--floating-panel__box {
            border: $spacer-thin solid $col-action-bar-float-box-bg;
            border-radius: $spacer-tiny;
            background: $white;
        }
        &--mat-date-picker{
            .mat-form-field-wrapper {
                padding-bottom: 0px;
            }
            .mat-form-field-infix {        
                width: $curtail-action-bar-date-picker-width;
            }            
            .mat-form-field-underline {
                height: 0px;
            }
            .mat-input-element:disabled{
                color: $col-ctl-datepicker-input-disabled;
            }
            .mat-mdc-form-field-subscript-wrapper{
                display: none;
            }
            .mat-mdc-form-field-infix{
                width: 100%;
            }
        }
        .text-counter{
            opacity: 0.6;
        }
    }
}