label{
    opacity: 0.7;
    color: $col-label-default;
    @extend .font--default--bold;
    font-size: $label-font-size;
    line-height: $default-line-height;
    //white-space: nowrap;
}

.label-text{
    @extend .font--default--bold;
    // IE fix
    word-break: break-all;
    word-wrap: break-word;
}

.label-text_only_wrap{
    @extend .font--default--bold;
    // IE fix
    word-wrap: break-word;
}

.label-od-text {
    @extend .label-text;
    font-weight: bold;
}

.od-text {
    font-size: $spacer-small;
}

.week-number-info {
    margin-top: $button-margin-default + 1;
    font-size: $icon-font-size;
    font-weight: bold;
    text-align: center;
}