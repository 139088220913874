.video{
    background: $white;
    height: $video-height;
    width: $video-width;
    }

    .videoblock {
        background-color: grey;
        margin: $videoblock-margin;
        width: $videoblock-width;
    }

    div.item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
}
    .caption {
    display: block;
    background-color: white;
}
