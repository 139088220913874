@font-face {
	font-family: 'Rubik Medium';
	src: url('../../../assets/fonts/maeu/rubik-regular.eot');
	src: url('../../../assets/fonts/maeu/rubik-regular.eot?#iefix') format('embedded-opentype'),
		url('../../../assets/fonts/maeu/rubik-regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Maersk Text';
	src: url('../../../assets/fonts/maeu/maersk-text-regular.otf');
	src: url('../../../assets/fonts/maeu/maersk-text-regular.otf?#iefix') format('embedded-opentype'),
		url('../../../assets/fonts/maeu/maersk-text-regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}



$font--default: (
	family:	            'Maersk Text',
	small:(
		size:			14px,
		tracking:		35
	),
	medium:(
		size:			14px,
		tracking:		35
	),
	large:(
		size:			14px,
		tracking:		35
	)
);

$font--default--bold: (
	family:	            'Maersk Text',
	small:(
		size:			14px,
		tracking:		35
	),
	medium:(
		size:			14px,
		tracking:		35
	),
	large:(
		size:			14px,
		tracking:		35
	)
);

$font--heading--default: (
	family:	            'Zeta Serif',
	small:(
		size:			14px,
		tracking:		35
	),
	medium:(
		size:			14px,
		tracking:		35
	),
	large:(
		size:			14px,
		tracking:		35
	)
);

