.request-details-container{
    @include box-shadow(  $spacer-tiny*0.5, - $spacer-tiny*0.25, $bg-dark);
    @include padding( $heading-medium-padding, $heading-medium-padding, $heading-medium-padding, $heading-medium-padding );
}
.border-radius{
    @include border-radius(3px);
}
// Overrides for Material Designs
.mat-expansion-panel-header{
    height: auto !important;
}
.mat-content{ padding:  $spacer-small+4;}

.comment.mat-expansion-panel-header,
.comment.mat-expansion-panel-header:focus,
.comment.mat-expansion-panel-header.mat-expanded:focus,
.comment.mat-expansion-panel-header.mat-expanded:hover,
.mat-expansion-panel:not(.mat-expanded) .comment.mat-expansion-panel-header:not([aria-disabled=true]):hover
{
    background: none !important;
    color: $col-label-default;
    font-size: 14px;
    opacity: 0.7;
    padding-right: 0 !important;
    pointer-events: none;
}
.comment.mat-expansion-panel {
    background: none !important;
}
.comment + .mat-expansion-panel-content {
    height: 0;
}
.comment .mat-content{
    flex-direction: column;
    padding: 0;
    word-break: break-word;
}
.comment a {
    pointer-events: auto;
}
.comment a.ng-star-inserted {
    color: $accent-secondary;
}
.jumbo-box #reason .comment a {
    color: $accent-secondary;
}

.week-count{
    // Important is added here to override default Styling from normalize.scss
    height: $spacer-large;
    display: inline-block;
    margin-left: $spacer-large;
    input[type="text"]{
        text-align: center;
        color: $black;
    }
}

.week-count-input{
    width: $number-of-week-width !important;
    height: $spacer-gutter;
}

.manualValidationOption{
    margin-left: $spacer-cent * 6;
}

.audit-box-modal{
    .model__box{
        width: $audit-modal-width;
        .alternate-row{
            background-color: $col-status-grey;
        }
    }

}

.contract-overview {
    .background{
        background-color: $grey4;
    }
    .margin {
        margin: $contract-overview-row-margin 0 0 0;
    }
}

.contract-overview-width {
    flex-basis: 150% !important;
}

.include-exclude-radios {
    .mat-radio-label-content {
        white-space: pre-wrap;
    }
}

.mat-checkbox-layout{
    font-size: 14px;
    opacity: 1 !important;
}

.mat-checkbox-inner-container {
    display: inline-block;
    height: 18px !important;
    line-height: 0;
    margin: auto;
    margin-right: 8px;
    order: 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: 18px !important;
    flex-shrink: 0;
}

.mat-checkbox-frame {
    background-color: transparent;
    transition: border-color 90ms cubic-bezier(0,0,.2,.1);
    border-style: solid;
    border-width: 1.25px !important;
    border-color: #003E5E !important;
}

.product-calendar-multi-od {
    margin-top: $spacer-cent;
}

.product-control-panel-multi-od {
    margin-top: $spacer-tiny * 2;
}

.multi-od-scrollable-text, .view-all-od-dialog-text {
    font-weight: bold;
}

/* Following styles are applicable for multi od history */
.delete_requested, .deleted {
    .multi-origin-name {
        color: black;
        text-decoration: line-through;
    }

    .multi-destination-name {
        color: black;
        text-decoration: line-through; 
    }
}

/* Styles for multi od scroll element and input element */

.delete-requested-scroll, .input-location-delete-requested {
    color: black;
    text-decoration: line-through;
}

/* style for multi od input element */

.input-location-added {
    color: red;
}

/* OD cancel confirmed alert text when allocation has vas */
.cancel-confirmed-alert-text {
    color: red;
    font-size: 16px;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-icon-color: #003E5E;
    --mdc-checkbox-selected-focus-icon-color: #003E5E;
    --mdc-checkbox-selected-hover-icon-color: #003E5E;
    --mdc-checkbox-selected-pressed-icon-color: #003E5E;
}
