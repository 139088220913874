.drawer-container{
    min-height: 100vh;
}

.drawer-handle{
    position: fixed;
    bottom: $spacer-medium;
    > .mat-raised-button {
        min-width: $spacer-large;
    }
}

.mat-drawer-inner-container{
    width: $spacer-cent !important;
}

.drawer-sidenav{
    &.mat-drawer{
        background: $col-drawer-bg;
        position: fixed;
    }
    &__menu{
        display: flex;
        flex-direction: column;
        margin-top: $side-bar-menu-margin;
        position: fixed;
        &__item{
            &__button{
                color: $col-draweer-menu-link-color;
                background: transparent;
                border: none;
                width: $percentage-full;
                padding: $side-bar-menu-item-padding;
                &:hover{
                    color: $col-drawer-menu-color;
                }
                &__active{
                    color: $col-drawer-bg;
                    background: $col-drawer-menu-active-bg;
                    opacity: 0.6;
                }
            }
            
            &__icon{
                font-size: $spacer-jumbo;
                line-height: $spacer-jumbo;
            }
            &__text{
                text-align: center;
                @extend .font--default--bold;
            }
        }
    }
}
