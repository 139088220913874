.mat-tooltip {
  &.radio-tooltip {
    background-color: #003E5E;
    font-size: 12px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  &.span-tooltip {
    background-color: #003E5E;
    font-size: 12px;
    margin-top: 3px;
  }
}
