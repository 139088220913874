.compliance{
    min-width: $chart-width;
    max-width: $chart-width;
    min-height: $chart-height;
    overflow-y: auto;
    &__close{
        float: right;
        cursor: pointer;
        font-size: $spacer-small;
    }
    &__label--small{
        font-size: $spacer-small - 4;
    }
    &__bigText-in-percentage{
        font-size: $spacer-medium;
        color: $compliance-percentage;
        font-weight: bold;
    }
    &__bigText-in-FFE{
        font-size: $spacer-medium;
        color: $compliance-ffe;
        font-weight: bold;
    }
    &__current-week-bar{        
        box-sizing: border-box;
        height:  $chart-today-bar-height;
        width: $chart-today-bar-width;
        border: ($chart-today-bar-width * 0.5) solid $compliance-today-bar;
        position: fixed;
        top: $chart-today-bar-top;
    }

    &__current-week-lable{
        margin-left: $chart-today-bar-lable_margin-left;
        margin-top: $chart-today-bar-lable_margin-top;
    }
    &__container{
        width: $graph-container-width;             
        & .summary-container{
            &__label{
                width: $graph-summary-label-width;
            }
            &__content{
                width: $graph-summary-content-width;
            }
        }
    }
}

.flex-container {
  display: flex;
}

.flex-container > div {
  padding: $spacer-tiny + 2;
  font-size: $chart-font-size;
}