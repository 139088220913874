body, ul, li, h1, h2, h3 {
    margin: 0;
    padding: 0;

}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}

a {
    text-decoration: none;
}

body{
    @extend .font--default--bold;
}

.right{
    text-align: right;
}

.center{
    text-align: center;
}

body{
    @extend .font--default--bold;
}

.table-cell{
    display: table-cell;
}

.hide{
    display: none;
}

::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder  { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A3A3A3;
    font-size: 16px;
    line-height: 24px;
    opacity: 1; /* Firefox */
}

.display--inline{
    display: inline-flex;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

