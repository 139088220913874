.accelerate-container{
    display: flex;
    overflow-x: auto;
    width: 1072px;
    padding: $spacer-tiny $spacer-tiny;
    border: $spacer-thin solid #CCCCCC;
    border-radius: $spacer-thin*4;
    .volume-label{
        width: 165px;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        padding-right: $spacer-tiny;
        padding-top: 80px;
    }

    .accelerate-window{
        width: 100%;
        //height: 128px;
        overflow-x: auto;
        position: relative;
        .quarter-range-reel{
            position: relative;
            height: $spacer-medium;
            .line{
                position:absolute;
                top: 50%;
                border-top: $spacer-thin*2 solid;
                width: 100%;
            }
        }
        .week-number-reel{
            height: 56px;
            background-color: #003E5E;
        }
        .week-volume-reel{
            // height: $spacer-gutter;
            background-color: white;
        }

        .quarter-container{
            top: 0px;
            position: absolute;
            display: flex;

            .quarter{
                position: relative;
                height: $spacer-medium;
                border-right: $spacer-thin solid;
                
                &__label{
                    text-align: center;
                    width: 79px;
                    margin: auto;
                    position: relative;
                    background-color: white;
                }
            }

            .week-container{
                display: flex;
                padding-top: $spacer-tiny;
                &__item{
                    width: 80px;
                    color: white;
                    text-align: center;
                }
            }            
        }

        .volume-container{
            display: flex;
            padding: $spacer-tiny 0px;
            &__item{
                width: 80px;
                text-align: center;
            }
        }
        
        &::-webkit-scrollbar{
            height: $spacer-tiny;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #003E5E;
            border-radius: $spacer-tiny;
        }
        &::-webkit-scrollbar-track {
            background-color: #69B8D6;
        }    
    }
}