.logout{
    position: relative;
    width: $percentage-full;
    &__container{
        position: absolute;
        border: $spacer-thin solid $logout-box-border;
        padding: $spacer-small;
        background: $logout-container-background;
        width: $percentage-full;
        &__info{
            color: $black;
        }
    }
}