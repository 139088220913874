.remove-icon {
  padding-top: $spacer-small;
  color: $col-cust-div-label;

  .fa {
    font-size: $spacer-medium + $spacer-thin*6;
  }
}

.remove-origin-destination-icon {
  padding-top: $spacer-gutter - $spacer-thin*5;
  color: $col-cust-div-label;

  .fa {
    cursor: pointer;
    font-size: $spacer-small + $spacer-thin*6;
  }
}
