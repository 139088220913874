@mixin flex-grow($grow-size){
    -webkit-flex-grow: $grow-size;
    -moz-flex-grow: $grow-size;
    -ms-flex-grow: $grow-size;
    flex-grow: $grow-size;
    -webkit-flex-grow: $grow-size;
    -moz-flex-grow: $grow-size;
    -ms-flex-grow: $grow-size;
    flex-basis: $percentage-full*$grow-size;    
}

@mixin display-flex{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
}

@mixin padding( $top, $right, $bottom, $left ){
    padding: $top $right $bottom $left;
}

@mixin box-shadow($shadow, $blur, $color){
    box-shadow: 0 0 $shadow $blur $color;
}

@mixin margin( $top, $right, $bottom, $left ){
    margin: $top $right $bottom $left;
}

@mixin border-radius( $radius ){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}



