.draft{
    color: $col-status-grey !important;
}
.pending{
    color: $col-status-yellow-1 !important;
}
.confirmed{
    color: $col-status-green !important;
}
.rejected{
    color: $col-status-red !important;
}
.counter-offered{
    color: $col-status-yellow-1 !important;
}
.expired{
    color: $col-status-grey !important;
}
.cancelled{
    color: $col-status-red !important;
}
.renegotiation{
    color: $col-status-yellow-1 !important;
}
.curtailment{
    color: $col-status-yellow-1 !important;
}

// Styling for Request details page to show color coding in a box
.status{
    padding: $spacer-small;
    border: $border-thick solid $col-status-border;
    color: $col-status;
    text-transform: uppercase;
    line-height: 19px;
    font-weight: bold;
    &__draft-box{
        background-color: $col-status-grey !important;
    }
    &__pending-box{
        background-color: $col-status-yellow !important;
    }
    &__confirmed-box{
        background-color: $col-status-green !important;
    }
    &__rejected-box{
        background-color: $col-status-red !important;
        color: $white;
    }
    &__counter-offered-box{
        background-color: $col-status-yellow !important;
    }
    &__expired-box{
        background-color: $col-status-grey !important;
        color: $white;
    }
    &__cancelled-box{
        background-color: $col-status-red !important;
        color: $white;
    }
    &__renegotiation-box{
        background-color: $col-status-yellow !important;
    }
    &__curtailment-box{
        background-color: $col-status-yellow !important;
    }
    &__pending{
        color: $col-status-yellow-1 !important;;
    }
    &__confirmed{
        color: $col-status-green !important;
    }
    &__renegotiation{
        color: $col-status-yellow-1 !important;
    }
    &__counter-offered{
        color: $col-status-yellow-1 !important;
    }
}

.status-od {
    margin-top: - $font-size-default;
    margin-left: $sidebar-padding-top;
    &__draft-box{
        background-color: $col-status-grey !important;
    }
    &__pending-box{
        background-color: $col-status-yellow !important;
    }
    &__confirmed-box{
        background-color: $col-status-green !important;
    }
    &__rejected-box{
        background-color: $col-status-red !important;
        color: $white;
    }
    &__counter-offered-box{
        background-color: $col-status-yellow !important;
    }
    &__expired-box{
        background-color: $col-status-grey !important;
        color: $white;
    }
    &__cancelled-box{
        background-color: $col-status-red !important;
        color: $white;
    }
    &__renegotiation-box{
        background-color: $col-status-yellow !important;
    }
    &__curtailment-box{
        background-color: $col-status-yellow !important;
    }
}

.status-od {
    padding: $input-padding-default;
    color: #313131;
    text-transform: uppercase;
    line-height: $model-content-line-height - $spacer-thin;
    font-weight: bold;
    font-size: $model-content-line-height + ($spacer-thin * 3);
}

.status-accelerate {
    padding: $spacer-tiny;
    color: $col-status;
    text-transform: uppercase;
    font-weight: bold;

    &__pending {
        &--background-color {
            background-color: $col-status-yellow !important;
        }
    }
    &__confirmed {
        &--background-color {
            background-color: $col-status-green !important;
        }
    }
    &__rejected {
        &--background-color {
            background-color: $col-status-red !important;
            color: $white;
        }
    }

    &__cancelled {
        &--background-color {
            background-color: $col-status-red !important;
            color: $white;
        }
    }

    &__counter_offered {
        &--background-color {
            background-color: $col-status-yellow !important;
        }
    }

}

.status-flexHub {
    padding: $spacer-tiny;
    color: $col-status;
    text-transform: uppercase;
    font-weight: bold;

    &__pending {
        &--background-color {
            background-color: $col-status-yellow !important;
        }
    }
    &__confirmed {
        &--background-color {
            background-color: $col-status-green !important;
        }
    }
    &__rejected {
        &--background-color {
            background-color: $col-status-red !important;
            color: $white;
        }
    }

    &__cancelled {
        &--background-color {
            background-color: $col-status-red !important;
            color: $white;
        }
    }

    &__counter_offered {
        &--background-color {
            background-color: $col-status-yellow !important;
        }
    }

}