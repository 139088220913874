
.mat-mdc-icon-button {
  padding: 0 !important;
  min-width: 0 !important;
  width: 40px !important;
  height: 40px !important;
}

.mdc-icon-button {
  white-space: nowrap;
}
.node-tree {
  background: white;
  overflow-y: scroll;
  height: $spacer-cent * 3;
}
.node-tree-invisible {
  display: none;
}

.node-tree ul,
.node-tree li {
  margin-top: 0;
  margin-bottom: 0;
  list-style-type: none;
}

.node-tree ul {
  margin-left: 24px;
}

.od-group-tree {
border: 1px solid #B2B2B2;
padding: 16px;
background: white;
border-radius: 4px;
overflow-x: auto;

.odg-search {
  position: relative;
  .odg-search__icon {
    position: absolute;
    right: 15px;
    top: 15px;
  }
}

.odg-search__input {
  width: $percentage-full;
  padding: $input-padding-default;
  font-size: $input-font-size-default;
  border: $border-thick solid $grey6;
  border-radius: $spacer-tiny - 2;
}

.mat-tree-node-text {
  margin-left: 15px;
  font-family: 'Maersk Text';
  font-style: normal;
  font-weight: 400;
  font-size: $spacer-small;
  line-height: $spacer-medium;
  color: #141414;
}
}
