.exceptionModel {
	position: fixed;
	z-index: 200;
	top: 0;
	left: 0;
	width: $percentage-full;
	height: $percentage-full;
	background: $exception-backround-color;
	overflow-y:scroll;
    overflow-x:hidden;
	&__box {
		position: absolute;
	}
	&__input {
		width: ($spacer-jumbo - 2);
	}
	&__div {
		background-color: $col-model-content-background;
		border-width: $spacer-thin;
		border-style: solid;
		border-color: $exception-div-border-color;
		border-radius: ($spacer-thin * 5);
		padding: $exception-text-padding;
	}
	&__text {
		position: absolute;
		width: $percentage-full;
		word-wrap: break-word;
		&__summary {
			bottom: $exception-text-summary-bottom;
			left: $exception-text-summary-left;
		}
		&__action-msg {
			bottom: $exception-text-action-msg-bottom;
			left: $exception-text-action-msg-left;
		}
	}
	&__btn {
		position: absolute;
		bottom: ($spacer-tiny + 2);
		&__no {
			left: ($spacer-large + 2);
		}
		&__confirm {
			left: $exception-text-btn-confirm-bottom;
		}
	}
}