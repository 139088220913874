.slider{
    &__week-allocation{
        width: 840px;
    }
    &__view-port{
        overflow: hidden;
        &-reel{
            display: flex;
            transition: transform 1s ease-in-out;
        }
        &-td{
            // Important is required here to override in weekly split. Otherwise the box overflows
            padding: $weekly-table-td-padding-top 0 !important;
            height: $spacer-large;
            width: 7%;
            vertical-align: middle !important;
        }
        &-text-td{
            // Important is required here to override in weekly split. Otherwise the box overflows
            padding: $weekly-table-td-padding-left !important;
            font-weight: bold;
            height: $spacer-large;
            vertical-align: middle !important;
        }
        &--tr{
            // important is required to overide default table style
            background-color: $white !important;
            height: $weekly-table-tr-height;
        }
    }
    &__content{
        flex-basis: $percentage-full;
        flex-grow: 1;
        width: $percentage-full;
        text-align: center;
        .week-volume-edit {
            padding: calc(#{$spacer-tiny} / 2); 
            text-align: center;
        }
    }
    &__controls{
        cursor: pointer;
        align-items: center;
        &-arrow{
            font-size: $spacer-large;
        }
        &-quarter{
            width: $quarter-navigation-width;
            padding: $spacer-small; 
            display: table-cell;
            &-dummy{
                color: $grey3;
            }
        }
    }
    &__quarter-number{
        font-size: $font-size-default;
        transition: transform 1s ease-in-out,
         -webkit-transform 1s ease-in-out, color 1s ease-in-out;
    }
    &__quarter-year{
        font-size: $font-size-default;
        transition: color 1s ease-in-out;
    }
    &__error{
        border: $spacer-thin solid $red;
    }
}

.arrow-disabled{
    color: $grey3;
}

.active-quarter{
    color: $col-anchor-default;
    .slider__quarter-number{
        transform: scale(2);
    }
}

.legends{
    color: $shadow-black;
    font-style: $legends-font-style;
    font-size:$font-size-default;
}

.split-disabled{
    pointer-events: none;
    color: $col-content-disabled;
    background: none;
    box-shadow: none;
    }

.error-panel{
    padding-top: $spacer-thin*2;
    display: flex;
    color: $red;
    .fa{
        font-size: $spacer-medium;
    }
}

.previous-volume-cbk{
    padding-top: $spacer-large;
}

.table-layout{
    table-layout: fixed;
}

.weekVolumeSummaryRow{
    font-size: calc(#{$spacer-medium} / 2);   
}
.adjustWeeksPaading{
    padding-top: $weekfee-padding-top !important;
}
.adjustWeeksSummaryPaading{
    padding-bottom: $weeksummary-padding-btm !important;
}
.cav-validity{
    color: $red;
}
.weekly-input {
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: $spacer-thin dotted $white;
    color: $white;
    outline: none;
    width: $max-min-input-width;
}