.create-response{
    .section-separator-bottom {
        border-bottom: $spacer-thin*2 solid $col-section-separator-border;
    }
    .display-flex {
        @include display-flex;
    }
    .flex-elem-auto{
        flex: auto;
    }
    .success-save{
        border: $spacer-thin solid $col-section-separator-border;
        background-color: rgba(139,182,21,0.1);
    }
    .success-submit{
        border: $spacer-thin solid $col-div-border-success;
        background-color: rgba(139,182,21,0.1);
    }
}