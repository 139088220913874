$accent-color:          #69B8D6;
$accent-secondary:      #003E5E;
$secondary-color:       #FAFAFA;
$maerskTeal:			#2F454E;
$bg-light:              #EEEEEE;
$bg-dark:               #313131;
$mat-ripple-color:      rgba(0, 62, 94, 0.26);

$border-dark:           #979797;

$yellow:                #F8E71C;
$yellow-1:              #F5A623;
$green:                 #7ED321;
$dark-green:            #006400;
$red:                   #D0021B;

$white:					#FFFFFF;

$button-hover:          #86C1EF;

$black:					#282828;

$grey1:					#535353;
$grey2:					#999999;
$grey3:					#A3A3A3;
$grey4:					#EDEDED;
$grey5:                 #737373;
$grey6:					#CCCCCC;
$grey7:                 #293d44;
$grey8:                 #b0b0b0;
$grey9:                 rgba(255, 255, 255, 0.75);
$grey10:                #4A4A4A;
$grey-line:             #DDDDDD;
$grey11:                #F7F7F7;

$utilityBlack:			#444444;
$bannerColor:			#FFB91C;

$cardsDarkBlue:			#266D8C;
$lightBlue1:            #D0D5EB;
$lightBlue2:            #4473C5;

$tbody-default-color:   #737373;

$link-default:          #2196F3;
$label-color-default:   #222222;
$pink1:                 #f8d7da;
$pink2:                 #f8f0d8;
$pink3:                 #F5403A;
$red1:                  #721c24;
$green1:                #8BB615;
$shadow-black:          #000000;
$error-color:           rgba(245,64,58,0.1);
$yellow-light:          #FFD44F;
$yellow-dark:           #FF9100;
$black-shadow:          rgba(0,0,0,0.5);
$orange:                #FFA500;
$accent-color-light:    #afd9e9;
// Message panel
$success-color:         rgba(139,182,21,0.1);
$amber:                 #ffbf00;
$blue:                  #0000FF;

