// reusable variables
$spacer-half:						0.5px;
$spacer-thin:                       1px;
$spacer-jumbo:						72px;
$spacer-big:						56px;
$spacer-large:						48px;
$spacer-medium:						24px;
$spacer-small:						16px;
$spacer-tiny:						8px;
$spacer-gutter:						40px;
$spacer-cent:                       100px;
$breakpoint-xs:                     320px;  /* Custom, iPhone Retina */
$breakpoint-min:					480px;  /* Extra Small Devices, Phones */
$breakpoint-medium:					768px;  /* Small Devices, Tablets */
$breakpoint-large:					992px;  /* Medium Devices, Desktops */
$breakpoint-max:					1200px; /* Large Devices, Wide Screens */
$container-max-width-medium:		720px;  /* Small Devices, Tablets */
$container-max-width-large:			960px;  /* Medium Devices, Desktops */
$container-max-width-max:			1140px; /* Large Devices, Wide Screens */

$default-line-height:			    $spacer-medium;

$percentage-full:                   100%;
$percentage-half:                   50%;
$percentage-small:                  15%;
$pill-width:                        $spacer-small * 5;

$plus-button-medium:                ($spacer-small * 2) - 2;
$datepicker-width:                  45%;

//Compliance graph
$compliance-margin-top:             $spacer-medium + 1;
// Weekly min and max variable
$max-min-input-width:               $spacer-cent - $spacer-small;
// Tooltip Variable
$max-width-tooltip:                 $spacer-cent * 3;
$tooltip-font-size:                 $spacer-small - 2;
$tooltip-padding:                   $spacer-thin * 3;
$tooltip-box-shaddow:               -$spacer-thin * 2;
$tooltip-border-radius:             $spacer-thin * 4;
$tooltip-left-margin:               -$spacer-thin * 5;
$tooltip-left-after:                $spacer-large + 2;
$tooltip-left-before:               $tooltip-left-after - $spacer-half;
$tooltip-border-before:             $spacer-tiny + 2;
$tooltip-border-after:              $tooltip-border-before - $spacer-half;
$tooltip-col-right-padding:         $spacer-small + 4;
$tooltip-last-col-padding:          $spacer-half;
$od-history-margin-bottom:          $spacer-small - $spacer-thin;
$od-history-padding:                $spacer-small + 4;

// Font variables
$font-size-default:                 $spacer-small - 2;
$font-size-header-default:          $spacer-small - 2;
$font-size-header-secondary:        $spacer-small + 4;
$font-weight-medium:                500;
$label-font-size:                   $spacer-small;
$legends-font-style:                italic;

// All Heading Variables here
$heading-large:                     $spacer-big;
$heading-medium:                    $spacer-medium + 6;
$heading-small:                     $spacer-medium;
$heading-medium-padding:            $spacer-medium - 4;
$heading-medium-margin:             $spacer-medium + 4;
// Input fields
$height-default:                    $spacer-large - 2;
$height-large:                      $spacer-large + 2;
$height-textarea:                   $spacer-jumbo;
$border-thin:                       $spacer-thin;
$border-thick:                      $spacer-thin*2;
$input-padding-default:             $spacer-small;
$input-font-size-default:           $spacer-small;
$height-select:                     $spacer-jumbo*2;

// table cell padding
$table-padding-small:               $spacer-small + 1;
$table-padding-tiny:                $spacer-tiny - 3;
$table-header-margin:               $spacer-small - 2;

// Button
$button-width-default:              ($spacer-big * 2) + 2;
$icon-font-size:                    $spacer-medium - 4;
$button-default-padding:            $spacer-small - 1;
$button-margin-default:             $spacer-medium - 4;
$button-medium:                     $spacer-large - 2;

// Search Icon
$search-margin-top:                 $spacer-small;
$search-margin-right:               30px;
$filter-section-padding:            $spacer-small + 4;
$autocomplete-height:               180px;
$autocomplete-border:               $spacer-thin;

//Checkbox
$checkbox-height:                   $spacer-small + 4;
$checkbox-width:                    $spacer-small + 4;

//page variables
$section-margin-top:                $spacer-small + 4;

//contract overview
$anchor-margin:                     $spacer-tiny + 2;
$contract-overview-row-margin:      $spacer-thin * 3;
$top-margin:                        $spacer-medium - 2;

//tile
$tile-height:                       ($spacer-large * 4) + $spacer-tiny;
$tile-width:                        ($spacer-gutter * 6);
$tile-head-height:                  ($spacer-gutter * 3) + $spacer-tiny + 2;
$tile-head-text-top:                $spacer-medium;
$tile-head-text-left:               ($spacer-tiny * 4) - 1;
$tile-text-top:                     $spacer-jumbo - 2;
$tile-text-left:                    $tile-head-text-left;
$tile-header-height:                $spacer-large*2 + $spacer-thin*4;
$tile-header-width:                 $spacer-jumbo*2 + $spacer-big;

//message
$message-height:                    $spacer-small * 4;

//Weekly Slider table padding
$weekly-table-td-padding-top:       $spacer-tiny + 2;
$weekly-table-td-padding-left:      $spacer-thin * 2;
$weekly-table-tr-height:            ($spacer-medium * 4) - 11;
$weekfee-padding-top:               $spacer-medium - 2;
$weeksummary-padding-btm:           $spacer-tiny + 2;

// Input Box
$input-box-width:                   $spacer-large + 2;

// Weekly Split
$weekly-slider-width:               $container-max-width-medium + 30;

// Quarter Navigation
$quarter-navigation-width:          33%;

// header
$sidebar-width:                     80px;
$sidebar-padding-top:               $spacer-tiny + 2;
$sidebar-padding-left:              $spacer-small - 1;
$nav-offset-top:                    $spacer-large * 2 + 2;
$sidebar-icon-label-font-size:      $spacer-small - 4;
$user-label-font-size:              $spacer-medium - $spacer-thin*1;

// SIDEBAR
$side-bar-menu-margin:              $spacer-cent;
$side-bar-menu-item-margin:         $spacer-small 0;
$side-bar-menu-item-padding:        $spacer-small;

// Feedback
$feedback-height:                   220px;
$feedback-content-padding:          30px;
$feedback-width:                    135px;

// model
$model-min-width:                   500px;
$model-max-width:                   90%;
$model-box-info-margin:             calc(#{$spacer-gutter} / 2);
$model-content-padding:             $spacer-small + 2;
$model-content-item-margin:         $spacer-small + 2;
$model-content-item-padding:        $spacer-tiny - 3;
$model-content-inner-padding:       65px;
$model-header-line-height:          28px;
$model-content-line-height:         $spacer-small + 4;
$model-header-letter-spacing:       0.05px;
$model-content-letter-spacing:      -0.1px;
$model-content-fixed-height:        400px;

// Audit modal
$audit-modal-width:                 70%;

// increamenter
$increment-button-padding:          $spacer-tiny + 2;

//video
$video-height:                      300px;
$video-width:                       530px;
$videoblock-width:                  70%;
$videoblock-margin:                 30px;

// Jumbo Box
$jumbo-box-padding:                 $spacer-medium;
$jumbo-box-label-font-size:         $spacer-small + 2;


// Date picker
$number-of-week-width:              $spacer-gutter * 2;

// accordion
$mat-panel-header-padding-top:      $spacer-small - 2;
$mat-content-padding-vert:          $spacer-tiny + 2;
$mat-content-padding-horz:          $spacer-medium - 4;
$mat-expansion-indicator-padding:   $spacer-tiny - 2;
$mat-expansion-border-padding:      $spacer-thin * 3;

// file upload
$file-upload-box-content-height:    150px;
$file-upload-box-height:            150px;
$file-upload-box-width:             600px;

//Snack Bar
$snack-bar-height:                  $spacer-large;
$snack-bar-width:                   344px;
$snack-bar-padding-left:            $spacer-small;
$snack-opacity:                     0.87;
$snack-bar-border-radius:           0.25rem;

// bold content
$content-bold-line-height:          $spacer-small + 1;
$content-bold-letter-spacing:       -0.1px;

// combobox
$combo-box-arrow-padding-vert:      $spacer-tiny;
$combo-box-arrow-padding-horz:      $spacer-medium;
$combo-bo-arrow-padding-right:      $spacer-tiny;
$combo-bo-arrow-padding-left:       $spacer-tiny;
$combo-box-font-default:            $spacer-medium;
$combo-box-font-small:              $spacer-small - 4;
$combo-box-border:                  $spacer-thin;
$combo-box-list-padding-vert:       $spacer-tiny - 3;
$combo-box-list-padding-horz:       $spacer-small;
$combo-box-header-padding:          $spacer-small;
$combo-box-list-suggestion-height:  ($spacer-gutter * 3) + $spacer-small;
$combo-box-list-selected-list:      100px;
$combo-box-arrow-top:               $spacer-thin * 2;
$combo-box-arrow-right:             $spacer-thin * 2;

// no records Pending Tray
$no-records-font-size:              $spacer-gutter - $spacer-tiny;

// error-panels
$error-close-padding-top:           $spacer-tiny - 2;
$error-close-padding-left:          $spacer-tiny;
$error-close-border-radius:         $spacer-medium - 4;
$info-padding:                      $spacer-small - 2;

// Radio Button
$radio-btn-border:                   1.25px;

// Button Style
$button-top:                        $spacer-tiny - 2;

//Release Updates
$releaseupdate-content-padding:     5px;
// download request details bar
$download-req-bar-height:           ($spacer-jumbo + 3);
$download-req-count-box-width:      ($spacer-large - 3);
$download-req-box-shadow:           ($spacer-thin * 2);
$download-req-padding-small:         $spacer-small - 4;
$download-req-margin-right:          $spacer-small * 2;

// compliance bar Chart
$chart-width:                        $spacer-cent * 10;
$chart-height:                       $spacer-cent * 7;
$chart-font-size:                    $spacer-small - 2;
$chart-today-bar-width:              $spacer-thin * 2;
$chart-today-bar-height:             ($spacer-cent * 5) + calc(40px / 2);
$chart-today-bar-top:                ($spacer-cent * 2 ) + calc(40px / 2);
$chart-today-bar-lable_margin-left:  -1 * (($spacer-cent) + ($spacer-thin * 20));

//transition time
$toggleTransition:					0.4s;
$chart-today-bar-lable_margin-left:  -1 * (($spacer-cent) + ($spacer-thin * 20));
$chart-today-bar-lable_margin-top:  -1 * ($spacer-thin * 10);

// tabs panel
$expand-height:                     $spacer-cent * 2;

// Calender Control - Date Picker
$cal-box-shadow-top:                 0 2px 4px -1px rgba(0,0,0,.2);
$cal-box-shadow-right:               0 4px 5px 0 rgba(0,0,0,.14);
$cal-box-shadow-bottom:              0 1px 10px 0 rgba(0,0,0,.12);

// OD right height image
$od-arrow-height:                   $spacer-gutter - 4;
$od-arrow-width:                    $spacer-gutter - 4;

// OD arrow image alignment
$od-arrow-image-position-top: 30px;
$od-arrow-image-position-right: 6px;

// Product Type
$product-input-padding:             calc(#{$spacer-tiny} / 2);
$product-scrollbar-height:          $spacer-tiny - 2;
$product-box-shadow-blur:           $spacer-tiny;
$product-box-shadow-spread:         calc(-1 * (#{$spacer-tiny} / 2));
$product-scrollable-offset:         calc(#{$spacer-medium} / 2);
$product-control-font-size:         calc(#{$spacer-medium} / 2);
$product-floating-panel-offset:     calc(#{$spacer-tiny} / 2);
$product-anchor-font-size:          $font-size-default;
$product-anchor-padding:            $spacer-thin * 2;
$product-detail-box-shadow:         0 0 $spacer-tiny -1 * $spacer-tiny;
$product-box-shadow-spread2:        -1 * $spacer-tiny;
$product-quarter-font-size:         $spacer-small;
$product-view-port-width:           $container-max-width-max - $spacer-small * 2;

// ODD Slider
$od-slider-width:                   $percentage-full * 2;

// heading
$heading-large:                     $spacer-large - $spacer-tiny;
$heading-small:                     $spacer-medium - 4;
$heading-small-line-margin:        ($spacer-medium - 4) * -1 * 0.5;

// form element
$form-element-offset-top:           ($spacer-small - 2) * -1;

//Exception modal varaibles
$exception-backround-color:        rgba(255, 255, 255, 0.8);
$exception-div-width:                $spacer-cent * 3;
$exception-div-height:               130px;
$exception-div-border-color:       rgba(121, 121, 121, 1);
$exception-text-summary-bottom:      ($spacer-cent - 20);
$exception-text-summary-left:        ($spacer-medium + 6);
$exception-text-action-msg-bottom:   ($spacer-medium + 6);
$exception-text-action-msg-left:     ($spacer-tiny + 2);
$exception-text-btn-confirm-bottom:  ($spacer-cent + ($spacer-large + 2));
$exception-text-padding:              $spacer-thin * 5;

//Curtailment action bar
$curtail-action-bar-left-right-margin:      5%;
$curtail-action-bar-min-height:             $spacer-small*5;
$curtail-action-bar-width:                  85%;
$curtail-action-bar-date-picker-width:      110px;
$curtail-action-bar-bottom-padding:         150px;

// sales feedback modal min width and height
$sales-feeback-modal-width:                        50%;
$sales-feeback-modal-min-height:                   50%;
$sales-feeback-modal-max-height:                   80%;
$sales-feeback-padding-for-no-data:                ($spacer-cent * 3) + calc(100px / 2 );

// service-contract-grid overview
$service-contract-grid-content-width:               $percentage-full;
$service-contract-grid-label-width:                 70%;
$service-contract-grid-row-height:                  $spacer-big + 2;

// service-contract-grid weekly breakdown
$service-contract-quarter-border-radius:            $spacer-thin * 5;
$service-contract-quarter-font-size:                $spacer-small + ($spacer-thin * 2);
$service-contract-quarter-line-height:              $spacer-small * 2;
$service-contract-quarter-width:                    116px;
$service-contract-quarter-arrow-font-size:          $spacer-small *2;
$service-contract-breakdown-width:                  75%;

// quarter navigator component
$quarter-dial-window-width:                         300px;
$quarter-dial-window-height:                        80px;
$quarter-dial-item-width:                           100px;
$quarter-dial-item-padding:                         $spacer-small + $spacer-thin*4;

// OD compliance graph
$graph-container-width:                             950px;
$graph-summary-label-width:                         75px;
$graph-summary-content-width:                       875px;
