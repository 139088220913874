// Overrides for Angular Material
// .mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
//   background-color: #003E5E;
//   --mdc-radio-selected-icon-color: #4b40ff
// }

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-icon-color:  #003E5E;
    --mdc-radio-unselected-pressed-icon-color: #003E5E;
    --mdc-radio-selected-focus-icon-color: #003E5E;
    --mdc-radio-selected-hover-icon-color: #003E5E;
    --mdc-radio-selected-pressed-icon-color:#003E5E;
    --mat-mdc-radio-ripple-color: #003E5E;
    --mat-mdc-radio-checked-ripple-color: #003E5E;

}

.mat-radio-label{
    opacity: 1;
    font-size: $font-size-default;
}
.mat-radio-outer-circle{
    border-width: $radio-btn-border !important;
    border-color: $col-model-radio-border;
}
.mat-radio-inner-circle{
    background-color: $col-model-radio-checked !important;
}
.mat-radio-checked{
    .mat-radio-inner-circle{
        transform: scale(.58) !important;
    }
    .mat-radio-outer-circle{
        border-color: $col-model-radio-checked !important;
    }
}
.radio-block .mat-radio-button {
    display: block!important;
}

.mat-mdc-radio-button{
    display :inline-block;
}

.mdc-form-field>label {
    white-space: nowrap;
    font-family: "Maersk Text";
    font-size: 14px;
    padding-bottom: 2px;
    padding-left: 6px !important;
}
.mat-mdc-radio-button {
  .mdc-radio{
      padding: 0 !important;
  }
}

.radio-flex{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  
}
.mdc-form-field :hover{
    cursor: pointer;
}

