.container{
    width: 1140px;
    margin: 0 auto;
}

.row{
    @include display-flex;
    justify-content: center; /* align horizontal */
    //align-items: center __<<ngThemingMigrationEscapedComment1>>__;
    justify-content: center;

    &--disabled {
        opacity: 0.7;
    }
}

.col-1{
    @include flex-grow(1);
}
.col-2{
    @include flex-grow(2);
}
.col-3{
    @include flex-grow(3);
}
.col-4{
    @include flex-grow(4);
}
.col-5{
    @include flex-grow(5);
}
.col-6{
    @include flex-grow(6);
}
.col-7{
    @include flex-grow(7);
}

.col-8{
    @include flex-grow(8);
}

.col-9{
    @include flex-grow(9);
}

.col-10{
    @include flex-grow(10);
}

.col-11{
    @include flex-grow(11);
}

.col-12{
    @include flex-grow(12);
}
.col-13{
    @include flex-grow(13);
}

