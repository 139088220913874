.datepicker{
    box-shadow: $cal-box-shadow-top, $cal-box-shadow-right, $cal-box-shadow-bottom;
    width: 296px;
    height: 354px;
}

.date-validity{
    .datepicker{
        background: $col-validity-period;
    }
    width: 100%;
    .mat-mdc-form-field-subscript-wrapper{
        display: none;
    }
}

.validity-margin {
  margin-top: $heading-medium-margin;
}
