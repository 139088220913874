.ng-tooltip {
    position: absolute;
    max-width: $max-width-tooltip;
    font-size: $tooltip-font-size;
    text-align: center;
    color: $black;
    padding: $tooltip-padding $spacer-tiny;
    background: $white;
    border-radius: $tooltip-border-radius;
    border: $spacer-half solid $grey2;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    box-shadow: $tooltip-box-shaddow $tooltip-box-shaddow $tooltip-padding $grey2;
    &-show {
      opacity: 1;
    }
    &:after {
      content: "";
      position: absolute;
      border-style: solid;
    }
    &-bottom {
       &:before {
          content: '';
          display: block;  
          position: absolute;
          bottom: $percentage-full;
          margin-left: $tooltip-left-margin;
          left: $tooltip-left-before;
          border: $tooltip-border-before solid transparent;
          border-bottom-color: $black;
      }
      &:after {
          content: '';
          display: block;  
          position: absolute;
          bottom: $percentage-full;
          left: $tooltip-left-after;
          margin-left: $tooltip-left-margin;
          border: $tooltip-border-after solid transparent;
          border-bottom-color: $white;
      }
    }
    &-top {
      &:before {
          content: '';
          display: block;  
          position: absolute;
          top: $percentage-full;
          margin-left: $tooltip-left-margin;
          left: $tooltip-left-before;
          border: $tooltip-border-before solid transparent;
          border-bottom-color: $black;
      }
      &:after{
          content: '';
          display: block;  
          position: absolute;
          top: $percentage-full;
          left: $tooltip-left-after;
          margin-left: $tooltip-left-margin;
          border: $tooltip-border-after solid transparent;
          border-bottom-color: $white;
      }
    }
    &-left{
      &:after{
          content: '';
          display: block;  
          position: absolute;
          top: $percentage-half;
          left: $percentage-full;
          margin-top: $tooltip-left-margin;
          border: $tooltip-border-after solid transparent;
          border-bottom-color: $white;
      }
    }
    &-right{
      &:after{
          content: '';
          display: block;  
          position: absolute;
          top: $percentage-half;
          right: $percentage-full;
          margin-top: $tooltip-left-margin;
          border: $tooltip-border-after solid transparent;
          border-bottom-color: $white;
      }
    }
    td {
      padding-right: $tooltip-col-right-padding;
      text-align: left;
    }
    
    td:last-child{
      padding-right: $tooltip-last-col-padding;
    }
  }
  
