.form_element{
    background-color: $col-form-element-bg;
    padding: $spacer-small;
    border: $spacer-thin solid $col-form-element-border;
    position: relative;
    &__label{
        position: absolute;
        top: $form-element-offset-top;
        background: white;
        padding: 0 $spacer-tiny 0 $spacer-tiny;
        opacity: 1;
    }
    &__content{
        margin-top: $spacer-tiny;
    }
}