.pending-tray { 
    .hide{
        display: none;
    }
    .fixed-td{
        width: 160px;
    }
    .fixed-td-large-width{
        width: 170px;
    }
    .sort{
        padding: 0 0 0 5px;
        > .fa{
            display: block;
            cursor: pointer;
        }
        &--disabled{
            color: $grey3;
        }
        .sort-icon{
            width: 12px;
            height: 6px;
            background: url('/assets/img/sort_arrow.png') center center;
            background-size: cover;
            margin-bottom: 2px;
        }
        .sort-icon-inverted{
            transform: rotate(180deg);
        }
    }

    .pagination{
        @extend .font--default--bold;
        > .pagination-disabled{
            color: $grey3;
        }
    }
    .section-margin{
        margin: $spacer-small+4 0;
    }

    .custom-add{
        display: table-cell;
        color: $white;
        font-weight: bold;
    }

    .input-margin{
        margin: $spacer-small+4 0;
    }

    td:first-child {
        border-right: $spacer-thin solid $col-border-table;
    }
    
    th:first-child {
        border-right: $spacer-thin solid $col-heading-default;
    }
    
    tbody tr:hover {
        background-color: $grey4;
    }
    .filter-container{
        display: grid; 
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: $spacer-small;
        row-gap: $spacer-small*2;
    }
}
.switch-view{
    padding: $spacer-tiny + 2;
    transition: all 0.5s ease-in-out;
}
.rotate90{
    transform: rotate(90deg);
}

.pagingOptions{
    width: $spacer-jumbo;
}

.border-bottom{
    border-bottom: $border-thick solid $grey6;
    padding-bottom: $spacer-medium;
}

.border-bottom_without_pad{
    border-bottom: $border-thick solid $grey6;
}

.custom-btn-margin{
    margin: 0 0 0 $button-margin-default !important;
}

.no-records-found{
    font-weight: inherit;
    font-size: $no-records-font-size;
}

.status-td{
    width: $spacer-cent;
}

.toggleFlag{    
    &--le::after{
        content: "\2264";
        font-size: 2em;
    }
    &--gt::after{
        content: "\003E";
        font-size: 2em;
    }    
}

.contract-overview {
    background-color: $grey4;
}