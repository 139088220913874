
.calendar-state {
    &.mat-expansion-panel {
        & .mat-expansion-panel-header {
            cursor: default;
            background: transparent;
            height: $spacer-thin - $spacer-thin !important;
        }
    }
}

.calendar-state {
    &.mat-expansion-panel {
        &:not(.mat-expanded) {
            & .mat-expansion-panel-header {
                &:not([aria-disabled=true]) {
                    &:hover {
                        background: transparent !important;
                    }
                }
                
            }
        }
    }
}

.calendar-state {
    &.mat-expansion-panel {
        & .mat-expansion-panel-header {
            &:focus {
                background: transparent !important;
            }
        }

    }
    & .mat-expansion-panel-content {
        user-select: none;
    }
    & .mat-expansion-panel-body {
        padding: $spacer-thin - $spacer-thin;
    }
    & .committed {
        display: none;
    }
}