.quantity-selector{
    width: $percentage-full;
    position: relative;
    input[type="text"]{
        text-align: center;
        background-color: $col-text-light;
    }
    &__increamenter{
        color: $black;
        position: absolute;
        @extend .font--default--bold;
        padding: $increment-button-padding;
        font-size: 30px;
        top: 0;
        -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;
        &--disabled{
            color: $grey6;
        }
    }
    &__subtraction{
        left: 0;
    }
    &__addition{
        right: 0;
    }
}

.small-size-numeric-box{
    width: $percentage-full*0.5 !important;    
}

.small-size-numeric-box-top{
    top: -$weekly-table-td-padding-top;
}