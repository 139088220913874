.model{
    position:fixed;
    z-index: 200;
    top:0;
    left:0;
    width: $percentage-full; 
    height: $percentage-full;
    background:rgba(255,255,255,0.8);
    .alternate-row{
            background-color: $col-status-grey;
    }
    &__box{
        position: absolute;
        max-height: 90%;
        border: $spacer-thin solid $col-model-border;
        top: $percentage-half;
        left: $percentage-half;
        margin-right: -$percentage-half;
        transform: translate(-$percentage-half, -$percentage-half);
        min-width: $model-min-width;
        max-width: $model-max-width;
        background: $col-model-content-background;
        overflow-y: auto;
        &__header{
            padding: $model-content-padding;
            font-size: $font-size-header-secondary;   
            @extend .font--default--bold;
            font-weight: bold;
            color: $col-model-color;
            letter-spacing: $model-header-letter-spacing;
            line-height: $model-header-line-height;
        }
        &__info{
            max-width: $model-min-width;
            padding: 0 $model-content-padding;
            margin: 0 0 $model-box-info-margin 0;
            line-height: $model-content-line-height;
            letter-spacing: $model-content-letter-spacing;
        }
        &__options{
            padding: 0 $model-content-padding;
        }
        &__content{
            overflow-y: auto;
            padding: 0 $model-content-padding;
            line-height: $model-content-line-height;
            &__radio-container{
                padding: $model-content-padding;
                background: $grey4;
                margin: $model-content-item-margin 0;
            }
            &--fixed-height{
                max-height: $model-content-fixed-height;
            }
            &--radio-label{
                display: table-cell;
            }
            &__reason{
                padding: $model-content-item-padding;
                letter-spacing: $model-content-letter-spacing;
            }
            &--padding-large{
                padding: 0 $model-content-inner-padding;
            }
            &--padding-normal{
                padding: $model-content-padding;
            }
            &--fixed{
                width: $spacer-big;
            }
        }
        &__error{
            padding: 0 $model-content-padding;
        }
        &__prompt-action{
            padding: $model-content-padding;
        }
    }
}