.feedback{
    background: $grey4;
    &__content{
        padding: $feedback-content-padding;
        &--align-right{
            text-align: right;
        }
    }
    &__content-button{
        padding: 0 $feedback-content-padding $feedback-content-padding $feedback-content-padding;
    }
    textarea {
         resize: none;
         height: $feedback-height;
         width: $percentage-full;
    }
}