.page-title {
    cursor: pointer;
    color: $col-title-default;
    @extend .font--default--bold;
    font-size: $font-size-default;
    font-weight: $font-weight-medium;
    line-height: $font-size-default;
    display: inline-block;
    > i {
        font-size: $icon-font-size;
    }
    > span{
        padding: 0 $input-padding-default*0.5;
    }
}

%padding-left{
    @include padding( 0, $spacer-small+4, 0, 0 );
}

.padding-left{
    @extend %padding-left;
}
.padding-right{
    @extend %padding-left;
}

.padding-left{
    @extend %padding-left;
}

.filter-section{
    padding: $filter-section-padding;
    border: 1px solid $col-border-filter;
}