@mixin fontStyles($fontStyleMap) {
	font-family: map-get($fontStyleMap, family);
	font-size: map-deep-get($fontStyleMap, small, size);

	@if (map-get($fontStyleMap, bold)) {
		font-weight: bold;
	}

}

.font--default {
	@include fontStyles($font--default);
	line-height: $default-line-height;
}

.font--default--bold {
	@include fontStyles($font--default--bold);
	line-height: $default-line-height;
}

.font--heading--default{
	@include fontStyles($font--heading--default);
	line-height: $default-line-height;
}