.accelerate-view {
    .mat-expansion-indicator {
        display: block;
    }
    .mat-expansion-panel-header {
        background: #ffffff;
    }

    .mat-expansion-indicator::after {
        color: #000000 !important;
    }

    .mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
        background: #ffffff !important;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
        background: #ffffff !important;
    }
}

.flexhub-view {
    .mat-expansion-indicator {
        display: block;
    }
    .mat-expansion-panel-header {
        background: #ffffff;
    }

    .mat-expansion-indicator::after {
        color: #000000 !important;
    }

    .mat-expansion-panel-header.mat-expanded:focus, .mat-expansion-panel-header.mat-expanded:hover {
        background: #ffffff !important;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
        background: #ffffff !important;
    }
    .mat-expansion-panel-content {
        line-height: 24px;
    }

}

.alert {
    padding: 20px;
    background-color: skyblue;
    color: black;
    border-radius: 8px;
    margin: 8px;
    font-weight: normal;
    font-size: 14px;
  }

  .alert-msg {
    background-color: skyblue;
    color: black;
    border-radius: 8px;
    margin: 8px;
  }

  .accelerate_od_panel {
    max-height: 200px;
    overflow: auto;
} 

.warning-msg{
    display: inline-block;
    padding-left: 42px;
    
}  