a{
    color: $col-anchor-default;
    cursor: pointer;
    @extend .font--default--bold;
}

a.disabled {
    pointer-events: none;
    cursor: not-allowed; 
    color: $col-disable-link;
    border-bottom: none !important;
 }

.clickable {
    cursor: pointer;
}