.weekly-breakdown-grid{
    &__content{
        max-width: $service-contract-grid-content-width;
    }
    &__label{
        max-width: $service-contract-grid-label-width;
    }    
    &__row_height{
        min-height: $service-contract-grid-row-height;
    }
    &__header-element{
        text-align: center;        
        border-radius: $service-contract-quarter-border-radius;
        font-size: $service-contract-quarter-font-size;
        line-height: $service-contract-quarter-line-height;
        width: $service-contract-quarter-width;
        cursor: pointer;
        &--active{
            background-color: $service-contract-quarter-bg-color;
            color: $service-contract-quarter-color;
        }
        &--arrow{
            font-size: $service-contract-quarter-arrow-font-size;
            cursor: pointer;
        }
    }
}